import React from 'react'
import Box from 'components/Box'
import Text from 'components/Text'
import CircularProgress, {getColorByValue} from 'components/CircularProgress'
import InverseKeyFeature from 'components/Platform/InverseKeyFeature'
import useLocalization from 'hooks/useLocalization'
import useTheme from 'hooks/useTheme'
import IProposalDetails from 'domain/IProposalDetails'
import {Tooltip} from '@mui/material'
import {formatDecimal} from 'helpers/format'
import {getCurrencySymbol} from 'helpers/price'

interface IProps extends React.PropsWithChildren {
  proposalDetails: IProposalDetails
  draft: boolean
  hasConsumption?: boolean
}

const getValue = value => {
  if (value) {
    return `${value.toFixed(0)}`
  }

  return '-'
}

const CircularMatch = ({value, draft}) => {
  const theme = useTheme()
  const tooltipText = draft
    ? 'Your hourly matching scores after signing this contract'
    : 'Your hourly matching scores at the time of signing this contract'

  return (
    <Tooltip title={tooltipText} arrow>
      <div>
        <Box margin={{bottom: 1}} justify="center">
          <CircularProgress
            trailColor={theme.colors.light1}
            value={value}
            color={getColorByValue(value, theme)}
            circleRatio={1}
          >
            <Text
              size="xlarge"
              semibold
              color={getColorByValue(value, theme)}
              font={theme.font.numbers}
              pad={{top: 0.5}}
            >
              {getValue(value)}
            </Text>
          </CircularProgress>
        </Box>
      </div>
    </Tooltip>
  )
}

const ProposalDetailsInfo: React.FC<IProps> = ({proposalDetails, draft, hasConsumption}) => {
  const {hourlyPercent, yearlyPercent, monthlyPercent} = proposalDetails?.greenCoverage || {}
  const {translate} = useLocalization()
  const averagePrice = +proposalDetails?.averagePrice
  const totalPrice = +proposalDetails?.totalPrice

  return (
    <Box direction="row" round>
      <Box pad={1} direction="row" align="end" width="100%" justify="space-between" height="124px">
        <InverseKeyFeature
          dataCy="price-per-certificate"
          title={translate('Price/Certificate')}
          value={averagePrice}
          formatFn={v => formatDecimal(v)}
          fallbackValueText={'-'}
          currency={getCurrencySymbol(proposalDetails?.currency)}
        />
        <InverseKeyFeature
          dataCy="total-price"
          title={translate('Total price')}
          value={totalPrice}
          fallbackValueText={'-'}
          formatFn={v => formatDecimal(v)}
          currency={getCurrencySymbol(proposalDetails?.currency)}
        />

        <InverseKeyFeature title={translate('Hourly match')}>
          <CircularMatch value={hasConsumption && hourlyPercent} draft={draft} />
        </InverseKeyFeature>

        <InverseKeyFeature title={translate('Monthly match')}>
          <CircularMatch draft={draft} value={hasConsumption && monthlyPercent} />
        </InverseKeyFeature>
        <InverseKeyFeature title={translate('Annual est.match')}>
          <CircularMatch draft={draft} value={hasConsumption && yearlyPercent} />
        </InverseKeyFeature>
      </Box>
    </Box>
  )
}

export default ProposalDetailsInfo
