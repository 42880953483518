import dayjs, {ManipulateType} from 'dayjs'
import dayOfYear from 'dayjs/plugin/dayOfYear'
import localeData from 'dayjs/plugin/localeData'

dayjs.extend(dayOfYear)
dayjs.extend(localeData)

export enum DateFormat {
  MONTH_DAY_YEAR = 'MMM D, YYYY', // Jul 30, 2008
  DAY_MONTH_YEAR_HOURS_MINUTES = 'DD.MM.YYYY HH:mm', // 12.10.1999 12:15
  MONTH_YEAR = 'MMM YYYY', // Jun 2024
  DAY_MONTH_YEAR = 'DD.MM.YYYY', // 12.01.2020
  YEAR_MONTH_DAY = 'YYYY-MM-DD', // 12-01-2020
  MONTH_SHORT = 'MMM',
  MONTH_LONG = 'MMMM',
  HOURS_MINUTES = 'HH:mm',
}

export function formatDate(date: string | Date, format: DateFormat | string = DateFormat.DAY_MONTH_YEAR): string {
  const parsedDate = dayjs(date)

  return parsedDate.isValid() ? parsedDate.format(format) : '-'
}

export function toDateRange(startYear: number, endYear: number): [Date, Date] {
  const startDate = startYear ? dayjs().set('year', startYear).startOf('year').toDate() : null
  const endDate = endYear ? dayjs().set('year', endYear).endOf('year').toDate() : null

  return [startDate, endDate]
}

export function getPeriod(
  date1: string | Date,
  date2: string | Date,
  format: DateFormat = DateFormat.DAY_MONTH_YEAR,
): string {
  return `${date1 ? formatDate(date1, format) : '...'} - ${date2 ? formatDate(date2, format) : '...'}`
}

export function toDate(date: string | Date, format?: string): Date | null {
  if (!date) {
    return null
  }

  return dayjs(date, format).toDate()
}

export function getYear(date: string | Date): number {
  if (!date) {
    return null
  }

  return dayjs(date).get('year')
}

export function getMonth(date: string | Date): number {
  if (!date) {
    return null
  }

  return dayjs(date).get('month')
}

export function getPercentageOfYear(date: string | Date): number {
  const dayOfYear = dayjs(date).dayOfYear()

  return dayOfYear / 365
}

export function getMinimumDate(...dates: Array<string | Date>): string | Date {
  let minDate: string | Date = null

  for (const date of dates) {
    if (date && (!minDate || dayjs(date).isBefore(minDate))) {
      minDate = date
    }
  }

  return minDate
}

export function getMaximumDate(...dates: Array<string | Date>): string | Date {
  let maxDate: string | Date = null

  for (const date of dates) {
    if (date && (!maxDate || dayjs(date).isAfter(maxDate))) {
      maxDate = date
    }
  }

  return maxDate
}

export function dateStartOf(date: string | Date, timeUnit: ManipulateType) {
  return dayjs(date).startOf(timeUnit).toDate()
}

export function dateEndOf(date: string | Date, timeUnit: ManipulateType) {
  return dayjs(date).endOf(timeUnit).toDate()
}

export function futureDate(date: string | Date, timeUnit: ManipulateType, amount: number) {
  return dayjs(date).add(amount, timeUnit).toDate()
}

export function isDateAfter(date: string | Date, other: string | Date): boolean {
  return dayjs(date).isAfter(other)
}

export function timeUntilDate(oldDate: string | Date, newDate: string | Date): number {
  return dayjs(oldDate).diff(dayjs(newDate), 'hours')
}

export function getCurrentYear(): number {
  return getYear(dayjs().toDate())
}

export function getCurrentMonth(): number {
  return getMonth(dayjs().toDate())
}

export function getPrevMonth(): string {
  return dayjs().subtract(1, 'month').format('MMMM')
}

export function dateByMonthAndYear(month: string, year: number): Date {
  if (!month || !year) {
    return null
  }
  const monthIndex = dayjs.months().indexOf(month)

  return dayjs().month(monthIndex).set('year', year).toDate()
}

export function lastDayOfMonth(month: number, year: number): number {
  const date = new Date()
  date.setFullYear(year)
  date.setMonth(month - 1)

  return dayjs(date).endOf('month').get('date')
}

export function getMonthNumber(month: string): number {
  return new Date(Date.parse(month + ' 1, 2024')).getMonth()
}

export function getMonthDays(month: number, year: number): number {
  return dayjs().month(month).set('year', year).daysInMonth()
}

export function getMonths(): string[] {
  return dayjs.months()
}

export function getPastYears(amount: number): number[] {
  return Array.from({length: amount + 1}, (v, k) => getCurrentYear() - k)
}

export function getHourlyInterval(date: string): string {
  const parsedDate = dayjs(date).add(1, 'hour')

  return `${formatDate(date, DateFormat.HOURS_MINUTES)} - ${formatDate(parsedDate.toDate(), DateFormat.HOURS_MINUTES)}`
}

export function generateYears(startingYear: number, amount: number): number[] {
  return Array.from({length: amount}, (v, k) => startingYear - k)
}
