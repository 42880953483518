import IProduct from '../domain/IProduct'
import IContentService from '../services/IContentService'
import {computed, observable, action} from 'mobx'
import RootStore from './index'
import IParty from '../domain/IParty'
import IProposalDetails, {IProposalDetailsBody} from 'domain/IProposalDetails'
import {ResourceId} from 'types'
import IV2ContentService from 'services/IV2ContentService'

export default class CertificateDesignerStore {
  private readonly rootStore: RootStore
  private readonly contentService: IContentService
  private readonly v2ContentService: IV2ContentService

  @observable public productsLoading = true
  @observable public productLoading = true
  @observable public detailsLoading = true
  @observable private product: IProduct
  @observable private details: IProposalDetails
  @observable public offerId: ResourceId

  public constructor(rootStore: RootStore, contentService: IContentService, v2ContentService: IV2ContentService) {
    this.rootStore = rootStore
    this.contentService = contentService
    this.v2ContentService = v2ContentService
  }

  @action
  public async loadProposalDetails(filter: IProposalDetailsBody) {
    try {
      const items = await this.v2ContentService.getCertificateProposalDetails(this.party.id, filter)

      this.details = items
    } finally {
      this.detailsLoading = false
    }
  }

  @action
  public async saveOffer(filter: IProposalDetailsBody): Promise<ResourceId> {
    try {
      const offer = await this.v2ContentService.saveCertificateOffer(this.party.id, filter)
      this.offerId = offer.id

      return offer.id
    } catch {
      return null
    }
  }

  @action
  public async editOffer(offerId: number, filter: IProposalDetailsBody): Promise<boolean> {
    try {
      await this.contentService.editCertificateOffer(this.party.id, offerId, filter)
      return true
    } catch {
      return false
    }
  }

  @action
  public async sendRequest(filter: IProposalDetailsBody): Promise<ResourceId> {
    try {
      if (this.offerId) {
        await this.contentService.sendCertificateReview(this.party.id, this.offerId)
      } else {
        const offer = await this.v2ContentService.saveCertificateOffer(this.party.id, filter)
        this.offerId = offer.id

        await this.contentService.sendCertificateReview(this.party.id, offer.id)
      }

      return this.offerId
    } catch {
      return null
    }
  }

  @action
  public setOfferId(id: ResourceId) {
    this.offerId = id
  }

  @action
  public setProductloading(loading: boolean) {
    this.productLoading = loading
  }

  @computed
  public get certificateProduct(): IProduct {
    return this.product
  }

  @computed
  public get proposalDetails(): IProposalDetails {
    return this.details
  }

  @computed
  private get party(): IParty {
    return this.rootStore.profileStore.party
  }
}
