import ITranslate from '../interfaces/ITranslate'
import {Country} from '../domain/ILocation'

export function getHumanReadableCountries(translate: ITranslate): {[key in Country]: string} {
  return {
    [Country.ALB]: translate('Albania'),
    [Country.AUT]: translate('Austria'),
    [Country.BEL]: translate('Belgium'),
    [Country.BIH]: translate('Bosnia and Herzegovina'),
    [Country.BGR]: translate('Bulgaria'),
    [Country.CHE]: translate('Switzerland'),
    [Country.CYP]: translate('Cyprus'),
    [Country.CZE]: translate('Czech Republic'),
    [Country.HRV]: translate('Croatia'),
    [Country.DEU]: translate('Germany'),
    [Country.DNK]: translate('Denmark'),
    [Country.ESP]: translate('Spain'),
    [Country.EST]: translate('Estonia'),
    [Country.FIN]: translate('Finland'),
    [Country.FRA]: translate('France'),
    [Country.GEO]: translate('Georgia'),
    [Country.GRC]: translate('Greece'),
    [Country.HUN]: translate('Hungary'),
    [Country.IRL]: translate('Ireland'),
    [Country.ISL]: translate('Iceland'),
    [Country.ITA]: translate('Italy'),
    [Country.LTU]: translate('Lithuania'),
    [Country.LVA]: translate('Latvia'),
    [Country.LUX]: translate('Luxembourg'),
    [Country.MNE]: translate('Montenegro'),
    [Country.NLD]: translate('Netherlands'),
    [Country.NOR]: translate('Norway'),
    [Country.PRT]: translate('Portugal'),
    [Country.SRB]: translate('Serbia'),
    [Country.SVN]: translate('Slovenia'),
    [Country.SWE]: translate('Sweden'),
    [Country.UKR]: translate('Ukraine'),
    [Country.GBR]: translate('United Kingdom'),
  }
}

export function getHumanReadableCountry(country: Country, translate: ITranslate) {
  const countries = getHumanReadableCountries(translate)

  return countries[country] || country
}

export function getProductCountry(product, translate: ITranslate) {
  const country = product?.site?.location?.addressCountry
  if (country) {
    return getHumanReadableCountry(country, translate)
  }

  return getHumanReadableCountry(Country.GBR, translate)
}
