import {computed} from 'mobx'
import ResourceStore from './ResourceStore'
import IContentService from '../services/IContentService'
import RootStore from './index'
import IContractType from '../domain/IContractType'

export default class ContractTypeStore extends ResourceStore<IContractType> {
  private contentService: IContentService
  private rootStore: RootStore

  public constructor(rootStore: RootStore, contentService: IContentService) {
    super()
    this.rootStore = rootStore
    this.contentService = contentService
  }

  public async loadContractTypes() {
    const party = this.rootStore.profileStore.party
    await this.setManyEventually(this.contentService.getContractTypes(party.id))
  }

  public async loadDefaultContractType() {
    const party = await this.rootStore.profileStore.party
    await this.setOneEventually(
      this.contentService.getDefaultContractType(party.id).then(c => ({...c, isDefault: true})),
    )
  }

  @computed
  public get default(): IContractType {
    return this.items.find(i => i.isDefault) || null
  }
}
