import ResourceStore, {RelationType} from './ResourceStore'
import IProduct from '../domain/IProduct'
import IContentService from '../services/IContentService'
import {computed} from 'mobx'
import RootStore from './index'
import IParty from '../domain/IParty'
import {
  isNotSoldOut,
  isProductOnSale,
  isProductOperational,
  isProductOperationalBroker,
  isProductUserSelectable,
} from '../helpers/product'

enum Keys {
  MANAGED = 'MANAGED',
  VISIBLE = 'VISIBLE',
}

export default class ProductStore extends ResourceStore<IProduct> {
  private readonly rootStore: RootStore
  private readonly contentService: IContentService

  public constructor(rootStore: RootStore, contentService: IContentService) {
    super([
      {
        type: RelationType.ManyToOne,
        key: 'site',
        getStore: () => rootStore.siteStore,
        inversedBy: 'products',
      },
    ])
    this.rootStore = rootStore
    this.contentService = contentService
  }

  public async loadVisibleProducts() {
    await this.setManyEventually(this.contentService.getProductsVisibleToParty(this.party.id), Keys.VISIBLE)
  }

  public async loadManagedProducts() {
    await this.setManyEventually(this.contentService.getProductsManagedByParty(this.party.id), Keys.MANAGED)
  }

  public async loadManagedProduct(id: any) {
    await this.setOneEventually(this.contentService.getProductManagedByParty(this.party.id, id))
  }

  public async loadVisibleProduct(id: any) {
    await this.setOneEventually(this.contentService.getProductVisibleToParty(this.party.id, id))
  }

  public async saveProduct(product: IProduct): Promise<IProduct> {
    const partyId = this.party.id

    await this.setOneEventually(
      product.id
        ? this.contentService.updateProduct(partyId, product)
        : this.contentService.createProduct(partyId, product),
    )

    return product
  }

  @computed
  public get managedProducts(): IProduct[] {
    return this.getItemsByKey(Keys.MANAGED)
  }

  @computed
  public get operationalManagedProducts(): IProduct[] {
    return this.managedProducts && this.managedProducts.filter(isProductOperationalBroker).filter(isNotSoldOut)
  }

  @computed
  public get managedProductsOnSale(): IProduct[] {
    return this.managedProducts && this.managedProducts.filter(isProductOnSale).filter(isNotSoldOut)
  }

  @computed
  public get visibleProducts(): IProduct[] {
    return this.getItemsByKey(Keys.VISIBLE)
  }

  @computed
  public get onSaleProducts(): IProduct[] {
    return this.visibleProducts && this.visibleProducts.filter(isProductOnSale)
  }

  @computed
  public get onSaleOperationalProducts(): IProduct[] {
    return this.visibleProducts && this.visibleProducts.filter(isProductOperational)
  }

  @computed
  public get marketplaceProducts(): IProduct[] {
    return this.onSaleProducts && this.onSaleProducts.filter(isProductUserSelectable)
  }

  @computed
  public get buyerMarketplaceProducts(): IProduct[] {
    return this.onSaleOperationalProducts && this.onSaleOperationalProducts.filter(isNotSoldOut)
  }

  @computed
  public get brokerMarketplaceProducts(): IProduct[] {
    return this.onSaleProducts && this.onSaleProducts.filter(isNotSoldOut)
  }

  @computed
  private get party(): IParty {
    return this.rootStore.profileStore.party
  }
}
