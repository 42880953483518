import ResourceStore, {RelationType} from './ResourceStore'
import IContractItem from '../domain/IContractItem'
import IContentService from '../services/IContentService'
import RootStore from './index'

export default class ContractItemStore extends ResourceStore<IContractItem> {
  private contentService: IContentService
  private rootStore: RootStore

  public constructor(rootStore: RootStore, contentService: IContentService) {
    super([
      {
        type: RelationType.ManyToOne,
        inversedBy: 'contractItems',
        getStore: () => rootStore.productStore,
        key: 'product',
      },
      {
        type: RelationType.OneToMany,
        inversedBy: 'contractItems',
        getStore: () => rootStore.contractStore,
        key: 'contract',
      },
    ])
    this.rootStore = rootStore
    this.contentService = contentService
  }
}
