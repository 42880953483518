import {ITimeseriesOverview} from 'domain/Portfolio'
import {observable} from 'mobx'
import IContentService, {Interval} from 'services/IContentService'
import RootStore from 'store'
import {ResourceId} from 'types'

export default class PortfolioStore {
  rootStore: RootStore
  contentService: IContentService
  @observable public portfolio: ITimeseriesOverview
  @observable public interval: Interval
  @observable public isLoading = true

  public constructor(rootStore: RootStore, contentService: IContentService) {
    this.rootStore = rootStore
    this.contentService = contentService
  }

  public async loadTimeseriesOverview(partyId: ResourceId, intervalType: Interval, start: Date, end: Date) {
    this.isLoading = true
    try {
      this.portfolio = await this.contentService.getPorfolioOverview(partyId, intervalType, start, end)
      this.interval = intervalType
    } catch (ex) {
      console.error(ex)
    } finally {
      this.isLoading = false
    }
  }
}
