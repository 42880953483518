import {useFormFieldName} from 'components/Form/FormFieldNamePrefixProvider'
import React, {useEffect, useState} from 'react'
import {Loader} from '@googlemaps/js-api-loader'
import PlacesAutocomplete, {geocodeByPlaceId, getLatLng} from 'react-places-autocomplete'
import {OptionTypeBase, StylesConfig} from 'react-select'
import ILocation from '../../../../domain/ILocation'
import {getStringifiedLocation} from '../../../../helpers/site'
import useFormContext from '../../../../hooks/useFormContext'
import useLocalization from '../../../../hooks/useLocalization'
import theme from '../../../../theme'
import FormAutocompleteSelect from '../../../Form/FormAutocompleteSelect'
import FormInput from '../../../Form/FormInput'
import {IProps} from '../../../Map/MapWithHeader'
import styled from 'styled-components'
import Box from 'components/Box'
import ITheme from 'theme/ITheme'
import env from '@beam-australia/react-env'

export const LocationBox = styled(Box)`
  pointer-events: all;
  color: ${props => (props.theme as ITheme).colors.secondary};
`

export interface ILocationSearch {
  placeId: string
  coordinatesLat: number
  coordinatesLon: number
  searchString: string
}

const inputLikeStyles: StylesConfig<OptionTypeBase, boolean> = {
  control: (provided, state) => {
    return {
      ...provided,
      backgroundColor: theme.colors.surface,
      borderColor: state.isFocused ? theme.colors.light1 : theme.colors.light1,
      boxShadow: 'unset',
      borderWidth: '1px',
      height: '40px',
      fontSize: '16px',
    }
  },
}

const contrastStyles: StylesConfig<OptionTypeBase, boolean> = {
  control: provided => {
    return {
      ...provided,
      backgroundColor: 'rgba(255, 255, 255, 0.4)',
      borderWidth: '1px',
      borderColor: theme.colors.secondary,
      height: '40px',
      fontSize: '16px',
    }
  },
}

const PLACES_API_STATUS_NO_RESULTS = 'ZERO_RESULTS'

const LocationSearch: React.FC<
  IProps & {
    location: ILocation
    variant?: 'primary' | 'contrast'
    required?: boolean
    placeholder?: string
    className?: string
    setLocation?: (location: ILocationSearch) => void
  }
> = ({location, setLocation, variant, placeholder, required, className}) => {
  const [googleLoaded, setGoogleLoaded] = useState<boolean>(false)
  const {translate} = useLocalization()
  const [searchString, setSearchString] = useState<string>(
    location?.searchString || getStringifiedLocation(location, translate) || '',
  )
  const {setValue} = useFormContext()
  const coordinatesLatField = useFormFieldName('location.coordinatesLat')
  const coordinatesLonField = useFormFieldName('location.coordinatesLon')
  const placeIdField = useFormFieldName('location.placeId')
  const searchStringField = 'location.search'
  const [isLoadingPlaceDetails, setIsLoadingPlaceDetails] = useState<boolean>(false)

  const onPlacesChanged = async searchString => {
    setSearchString(searchString || '')
  }

  const setPlace = async placeId => {
    setIsLoadingPlaceDetails(true)
    const results = await geocodeByPlaceId(placeId)
    const {lat, lng} = await getLatLng(results[0])

    setValue(coordinatesLatField, lat)
    setValue(coordinatesLonField, lng)
    setValue(placeIdField, placeId)

    setSearchString(results.formatted_address || '')
    setValue(searchStringField, searchString)

    setLocation &&
      setLocation({coordinatesLat: lat, coordinatesLon: lng, placeId, searchString: results[0]?.formatted_address})

    setIsLoadingPlaceDetails(false)
  }

  const onError = (status, clearSuggestionsFn) => {
    if (status === PLACES_API_STATUS_NO_RESULTS) {
      setIsLoadingPlaceDetails(false)
    }
    clearSuggestionsFn()
  }

  useEffect(() => {
    const loader = new Loader({
      apiKey: env('GOOGLE_API_KEY'),
      id: '__googleMapsScriptId',
      version: 'weekly',
    })

    loader.importLibrary('places').then(() => setGoogleLoaded(true))
  }, [])

  return (
    googleLoaded && (
      <PlacesAutocomplete value={searchString} onChange={onPlacesChanged} debounce={400} onError={onError}>
        {({getInputProps, getSuggestionItemProps, loading, suggestions}) => {
          const {onChange, value, onKeyDown, ...rest} = getInputProps()

          return (
            <>
              <FormAutocompleteSelect
                {...rest}
                className={className}
                isSearch
                onKeyDown={(event: React.KeyboardEvent) => {
                  // override the default event handler with a no-op handler,
                  // otherwise arrow key navigation for suggestions will not work
                }}
                styles={variant && (variant === 'primary' ? inputLikeStyles : contrastStyles)}
                required={required}
                name="location.search"
                placeholder={placeholder || translate('Search..')}
                defaultInputValue={value}
                onChange={placeId => {
                  setPlace(placeId)
                }}
                onInputChange={value => {
                  const event = {target: {value: value}}
                  onChange(event)
                  setIsLoadingPlaceDetails(true)
                }}
                options={suggestions.map(suggestion => ({
                  value: suggestion.placeId,
                  label: suggestion.description,
                }))}
                closeMenuOnScroll={e => e}
                isLoading={isLoadingPlaceDetails || loading}
              />
              <FormInput name="location.placeId" type="hidden" defaultValue={null} />
              <FormInput name="location.coordinatesLat" type="hidden" defaultValue={null} />
              <FormInput name="location.coordinatesLon" type="hidden" defaultValue={null} />
            </>
          )
        }}
      </PlacesAutocomplete>
    )
  )
}

export default LocationSearch
