import React, {useState} from 'react'
import RouterModal from '../../../../Modal/RouterModal'
import useLocalization from 'hooks/useLocalization'
import ICertificateOffer from 'domain/ICertificateOffer'
import useProfile from 'hooks/useProfile'
import useStore from 'hooks/useStore'
import {useHistory} from 'react-router-dom'
import ContractVerificationChallengeModal from 'components/Platform/Portfolio/ContractVerificationChallengeModal'
import VerifyPhoneModal from './Verify'
import Preview from './Preview'
import {ModalType} from 'components/Modal/IModal'

interface IProps extends React.PropsWithChildren {
  offer: ICertificateOffer
}

const PreviewModal: React.FC<IProps> = ({offer}) => {
  const [signing, setSigning] = useState(false)
  const {user} = useProfile()
  const {translate} = useLocalization()
  const {contractStore, alertStore} = useStore()
  const history = useHistory()
  const [showPhoneVerificationModal, setShowPhoneVerificationModal] = useState(false)
  const [verificationChallengeCompleted, setVerificationChallengeCompleted] = useState(false)
  const isVerified = user.phoneNumberVerified
  const showVerification = isVerified && signing && !verificationChallengeCompleted

  if (!offer) {
    return null
  }
  const {contract} = offer

  const signContract = async () => {
    try {
      await contractStore.signContract(contract.id)

      history.push(`/consumer/portfolio/current-contracts/${contract.id}`)
      alertStore.addSuccess(translate('Congratulations! Your contract is now signed'))
    } catch (e) {
      console.error(e)
      alertStore.addError(translate('Unable to sign contract'), e?.correlationId, e?.message)
      const urlSearchParams = new URLSearchParams(location.search)
      urlSearchParams.delete(ModalType.CERTIFICATE_PREVIEW)

      history.push(`?${urlSearchParams.toString()}`)
    }
  }

  const handleVerificationSuccess = async () => {
    await signContract()
    setVerificationChallengeCompleted(true)
  }

  const startSigning = () => {
    setSigning(true)
    setVerificationChallengeCompleted(false)
    setShowPhoneVerificationModal(true)
  }

  return (
    <RouterModal style={{maxWidth: 1200}} name={ModalType.CERTIFICATE_PREVIEW}>
      {!signing && <Preview offer={offer} startSigning={startSigning} />}
      {!isVerified && showPhoneVerificationModal && (
        <VerifyPhoneModal setSigning={setSigning} setShowPhoneVerificationModal={setShowPhoneVerificationModal} />
      )}
      {showVerification && (
        <ContractVerificationChallengeModal
          //TODO: remove this once the feature is considered stable
          onClose={() => {
            setVerificationChallengeCompleted(true)
            //skip the password verification as well
            setSigning(false)
          }}
          contract={contract}
          onSuccess={() => handleVerificationSuccess()}
        ></ContractVerificationChallengeModal>
      )}
    </RouterModal>
  )
}

export default PreviewModal
