import React from 'react'
import {Route, Switch, useRouteMatch, RouteComponentProps} from 'react-router'

interface IProps extends React.PropsWithChildren {
  create?: (routeProps: RouteComponentProps) => React.ReactNode
  edit?: (routeProps: RouteComponentProps<{id: any}>) => React.ReactNode
  single?: (routeProps: RouteComponentProps<{id: any}>) => React.ReactNode
  list: (routeProps: RouteComponentProps) => React.ReactNode
  custom?: React.ReactNode
}

const ResourceGateway: React.FC<IProps> = ({single, list, create, edit, custom}) => {
  const {url} = useRouteMatch()

  return (
    <Switch>
      {custom}
      {create && <Route path={`${url}/add`} render={create} />}
      {edit && <Route path={`${url}/edit/:id`} render={edit} />}
      {single && <Route path={`${url}/:id`} render={single} />}
      <Route path="*" render={list} />
    </Switch>
  )
}

export default ResourceGateway
