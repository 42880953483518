import React, {useEffect} from 'react'
import {IProps as IPageProps} from '../../Page'
import useResource from '../../../hooks/useResource'
import CertificateContractPage from './CertificateContractPage/CertificateContractPage'
import useStore from 'hooks/useStore'
import FullscreenLoader from 'components/FullscreenLoader'

interface IProps extends React.PropsWithChildren, IPageProps {
  id: any
  editable?: boolean
}

//TODO: remove this component and use CertificateContractPage directly
const SalesContractDetails: React.FC<IProps> = ({id}) => {
  const {purchaseContractStore} = useStore()
  const {isLoading, contract, contractIsLoading, partyLoaded} = useResource(store => ({
    isLoading: store.purchaseContractStore.isLoading,
    contract: store.purchaseContractStore.getItem(id),
    contractIsLoading: store.contractStore.isLoading,
    partyLoaded: store.profileStore.isLoaded,
  }))

  useEffect(() => {
    if (partyLoaded) {
      purchaseContractStore.loadPartySalesContracts()
    }
  }, [partyLoaded])

  if (isLoading || contractIsLoading || !contract || !partyLoaded) {
    return <FullscreenLoader />
  }

  return <CertificateContractPage offerId={contract.offer.id} />
}

export default SalesContractDetails
