import {PartyMemberRole} from 'domain/PartyMemberRole'
import IPartyMember, {RepresentationBasis} from '../../domain/IPartyMember'
import {mockBrokerParty, mockBuyerParty1} from './mockParties'

export default [
  {
    id: 1,
    representationBasis: RepresentationBasis.POWER_OF_ATTORNEY,
    party: mockBrokerParty,
    partyMemberRoles: [PartyMemberRole.PARTY_USER],
  },
  {
    id: 2,
    representationBasis: RepresentationBasis.MEMBER_OF_BOARD,
    party: mockBuyerParty1,
    partyMemberRoles: [PartyMemberRole.PARTY_USER],
  },
] as IPartyMember[]
