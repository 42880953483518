import {MarketplaceSort, Order} from 'domain/FilterType'
import IAttachment from 'domain/IAttachment'
import {IBuyerOnboardState} from 'domain/IBuyerOnboardState'
import ICertificateOffer from 'domain/ICertificateOffer'
import IContract, {IBuyerContract} from 'domain/IContract'
import {ICreateOnbBuyerSiteReq, ICreateOnbSellerSiteReq} from 'domain/ICreateOnbSiteReq'
import {IDetailedProfile} from 'domain/IDetailedProfile'
import IExternalContract, {IExternalContractPayload, IExternalContractUploadPayload} from 'domain/IExternalContract'
import IKPI, {IKPITargets} from 'domain/IKPI'
import {Country} from 'domain/ILocation'
import {
  IGenerateTestCertsPayload,
  IMarketListing,
  IMarketListingReport,
  IMarketListingUpdatePayload,
  INewMarketListing,
} from 'domain/IMarketListing'
import IMatchingScore from 'domain/IMatchingScore'
import INewTimeseriesItem from 'domain/INewTimeseriesItem'
import {INewOffer, INewSellerOfferPayload} from 'domain/IOffer'
import {IPaddle} from 'domain/IPaddle'
import {IPaginatedExtendedResult} from 'domain/IPaginateResult'
import IProduct, {IBasicProduct, IUpdateProduct} from 'domain/IProduct'
import IProposalDetails, {IProposalDetailsBody} from 'domain/IProposalDetails'
import {ITestCertificates} from 'domain/IRegoCertificate'
import {ISellerOnboardState} from 'domain/ISellerOnboardState'
import ISellExcessRequest from 'domain/ISellExcessRequest'
import ISite, {
  IBasicSite,
  ICreateBuyerSite,
  ISiteOnbSummary,
  IUpdateBuyerSiteGeneral,
  IUploadBuyerConsumption,
  SiteProductionTechnology,
} from 'domain/ISite'
import ISitesDashboard from 'domain/ISitesDashboard'
import ISubscription from 'domain/ISubscription'
import ITenderRequest from 'domain/ITenderRequest'
import {ITenderRespond, ITenderRespondCreatePayload} from 'domain/ITenderRespond'
import {CreateOnboardProductReq} from 'domain/IUploadProductRequest'
import IWarehouseCertificates from 'domain/IWarehouseCertificates'
import {IContractedVolume, ICoverageOverview, IFinancialPerformance} from 'domain/Portfolio'
import {ResourceId} from 'types'
import {Interval} from './IContentService'
import IHttpClient from './IHttpClient'
import IV2ContentService from './IV2ContentService'
import {IUserProfile} from 'domain/IUserProfile'

export default class V2ContentService implements IV2ContentService {
  private readonly httpClient: IHttpClient

  constructor(httpClient: IHttpClient) {
    this.httpClient = httpClient
  }

  public async updateUserProfile(payload: IUserProfile): Promise<IUserProfile> {
    const {data} = await this.httpClient.put<IUserProfile>(`/current-user/profile`, payload)
    return data
  }

  public async cancelSubscription(partyId: any, subscriptionId: number): Promise<ISubscription> {
    const {data} = await this.httpClient.post<ISubscription>(
      `/parties/${partyId}/subscriptions/paddle/${subscriptionId}/cancel`,
    )

    return data
  }

  public async getSubscription(partyId: any, subscriptionId: number): Promise<ISubscription> {
    const {data} = await this.httpClient.get<ISubscription>(`/parties/${partyId}/subscriptions/${subscriptionId}`)
    return data
  }

  public async getSubscriptions(
    partyId: any,
    page: number,
    size: number,
  ): Promise<IPaginatedExtendedResult<ISubscription>> {
    const {data} = await this.httpClient.get<IPaginatedExtendedResult<ISubscription>>(
      `/parties/${partyId}/subscriptions`,
      {
        params: {page: page, size: size},
      },
    )
    return data
  }

  public async getBuyerContracts(
    partyId: any,
    page: number,
    size: number,
  ): Promise<IPaginatedExtendedResult<IBuyerContract>> {
    const {data} = await this.httpClient.get<IPaginatedExtendedResult<IBuyerContract>>(
      `/parties/${partyId}/buyer/offers/executed`,
      {
        params: {page: page, size: size},
      },
    )
    return data
  }

  public async getPaddlePricing(partyId: number): Promise<IPaddle> {
    const {data} = await this.httpClient.get<IPaddle>(`/parties/${partyId}/payments/paddle/price-catalog`)

    return data
  }

  public async completeBuyerGuide(userId: any, userEmail: string): Promise<void> {
    await this.httpClient.post(`/current-user/buyer-guide/complete`, {
      id: userId,
      email: userEmail,
    })
  }

  public async getAdminExcessSellRequests(): Promise<IPaginatedExtendedResult<ISellExcessRequest>> {
    const {data} = await this.httpClient.get<IPaginatedExtendedResult<ISellExcessRequest>>(
      `admin/green-certs/sell-requests`,
    )

    return data
  }

  public async getWarehouseCertificates(): Promise<IWarehouseCertificates> {
    const {data} = await this.httpClient.get<IWarehouseCertificates>(`/admin/certificates/warehouse`)

    return data
  }

  public async getCertificateProposalDetails(
    partyId: any,
    body: IProposalDetailsBody,
    interval?: Interval,
  ): Promise<IProposalDetails> {
    const {data} = await this.httpClient.post<IProposalDetails>(
      `/parties/${partyId}/purchase-designer/offers/calculate?aggregation=${interval}`,
      {...body, numMeters: 1}, // TODO remove numMeters
    )

    return data
  }

  public async getPorfolioContractedVolumes(
    partyId: any,
    interval: Interval,
    year: number,
    month: string,
  ): Promise<IContractedVolume> {
    const {data} = await this.httpClient.get<IContractedVolume>(
      `/parties/${partyId}/portfolio-overview/contracted-volumes/${interval}`,
      {params: {year, month}},
    )

    return data
  }

  public async getCoverageOverview(partyId: any, interval: Interval): Promise<ICoverageOverview> {
    const {data} = await this.httpClient.get<ICoverageOverview>(
      `/parties/${partyId}/portfolio-overview/coverage/${interval}`,
    )

    return data
  }

  public async getPortfolioSites(partyId: any, page: number, size: number): Promise<IPaginatedExtendedResult<ISite>> {
    const {data} = await this.httpClient.get<IPaginatedExtendedResult<ISite>>(`/parties/${partyId}/sites`, {
      params: {page: page, size: size},
    })

    return data
  }

  public async getPortfolioSitesDashboard(partyId: any, interval: Interval): Promise<ISitesDashboard> {
    const {data} = await this.httpClient.get<ISitesDashboard>(`/parties/${partyId}/sites/dashboard/${interval}`)

    return data
  }

  public async getSellerOnboardingState(partyId: number): Promise<ISellerOnboardState> {
    const {data} = await this.httpClient.get<ISellerOnboardState>(`/parties/${partyId}/seller/onboard/state`)

    return data
  }

  public async getBuyerOnboardingState(partyId: number): Promise<IBuyerOnboardState> {
    const {data} = await this.httpClient.get<IBuyerOnboardState>(`/parties/${partyId}/buyer/onboard/state`)

    return data
  }

  public async startSellerOnboarding(partyId: any): Promise<ISellerOnboardState> {
    const {data} = await this.httpClient.post(`/parties/${partyId}/seller/onboard/start`)
    return data
  }

  public async startBuyerOnboarding(partyId: any): Promise<IBuyerOnboardState> {
    const {data} = await this.httpClient.post(`/parties/${partyId}/buyer/onboard/start`)
    return data
  }

  public async createSellerOnbSite(partyId: any, payload: ICreateOnbSellerSiteReq): Promise<ISellerOnboardState> {
    const {data} = await this.httpClient.post(`/parties/${partyId}/seller/onboard/site`, payload)
    return data
  }

  public async createBuyerOnbSite(partyId: any, body: ICreateOnbBuyerSiteReq): Promise<IBuyerOnboardState> {
    const {data} = await this.httpClient.post(`/parties/${partyId}/buyer/onboard/site`, body)
    return data
  }

  public async createSellerOnbProduct(partyId: any, req: CreateOnboardProductReq): Promise<ISellerOnboardState> {
    const formData = new FormData()

    for (const [key, value] of Object.entries(req)) {
      formData.append(key, value)
    }

    const {data} = await this.httpClient.post(`/parties/${partyId}/seller/onboard/product`, formData)
    return data
  }

  public async createBuyerOnbConsumption(partyId: any, attachmentId: number): Promise<IBuyerOnboardState> {
    const {data} = await this.httpClient.post(`/parties/${partyId}/buyer/onboard/consumption-profile`, {
      attachmentId,
    })
    return data
  }

  public async publishOnboardingProduct(partyId: number): Promise<ISellerOnboardState> {
    const {data} = await this.httpClient.post(`/parties/${partyId}/seller/onboard/publish-product`)
    return data
  }

  public async completeBuyerOnboarding(partyId: number): Promise<IBuyerOnboardState> {
    const {data} = await this.httpClient.post(`/parties/${partyId}/buyer/onboard/complete`)
    return data
  }

  public async getSellerOnbSiteSummary(partyId: any, interval: Interval): Promise<ISiteOnbSummary> {
    const {data} = await this.httpClient.get<ISiteOnbSummary>(
      `/parties/${partyId}/seller/onboard/site-summary/${interval}`,
    )
    return data
  }
  public async getBuyerOnbSiteSummary(partyId: any, interval: Interval): Promise<ISiteOnbSummary> {
    const {data} = await this.httpClient.get<ISiteOnbSummary>(
      `/parties/${partyId}/buyer/onboard/site-summary/${interval}`,
    )
    return data
  }

  public async getPortfolioMatchingScores(partyId: ResourceId): Promise<IMatchingScore> {
    const {data} = await this.httpClient.get<IMatchingScore>(`/parties/${partyId}/green-coverage/scores`)

    return data
  }

  public async getGreenCertificatesData(
    partyId: ResourceId,
    year: number,
    month: string,
    dayOfMonth?: number,
  ): Promise<INewTimeseriesItem> {
    const {data} = await this.httpClient.get<INewTimeseriesItem>(
      `/parties/${partyId}/portfolio-overview/green-certificates`,
      {
        params: {
          year,
          month,
          dayOfMonth,
        },
      },
    )

    return data
  }

  public async getCertificateProducts(
    partyId: any,
    month: string,
    year: number,
    page: number,
    size: number,
    sortBy: MarketplaceSort,
    order: Order,
    countries: Country[],
    technology: SiteProductionTechnology,
  ): Promise<IPaginatedExtendedResult<IProduct>> {
    const {data} = await this.httpClient.get<IPaginatedExtendedResult<IProduct>>(
      `/parties/${partyId}/certificate-marketplace/products`,
      {
        params: {
          year,
          month,
          page,
          size,
          sortBy,
          order,
          countries: countries.length > 0 ? countries.join(',') : undefined,
          technologies: technology,
        },
      },
    )

    return data
  }

  public async getCertificateProduct(partyId: any, productId: any, month: string, year: number): Promise<IProduct> {
    const {data} = await this.httpClient.get<IProduct>(
      `/parties/${partyId}/certificate-marketplace/products/${productId}?year=${year}&month=${month}`,
    )

    return data
  }

  public async getCertificatePurchaseOffer(partyId: any, offerId: any): Promise<ICertificateOffer> {
    const {data} = await this.httpClient.get<ICertificateOffer>(
      `/parties/${partyId}/purchase-designer/offers/${offerId}`,
    )

    return data
  }

  public async saveCertificateOffer(partyId: any, body: IProposalDetailsBody): Promise<ICertificateOffer> {
    const {data} = await this.httpClient.post<ICertificateOffer>(`/parties/${partyId}/purchase-designer/offers`, body)

    return data
  }

  public async sendTenderRequest(partyId: any, body: ITenderRequest): Promise<ITenderRequest> {
    const {data} = await this.httpClient.post<ITenderRequest>(`/parties/${partyId}/tender-requests`, body)

    return data
  }

  public async signContract(partyId: any, contractId: any): Promise<IContract> {
    const {data} = await this.httpClient.post(`/parties/${partyId}/contracts/${contractId}/sign`)
    return data
  }

  public async getPortfolioProducts(
    partyId: any,
    page: number,
    size: number,
  ): Promise<IPaginatedExtendedResult<IProduct>> {
    const {data} = await this.httpClient.get<IPaginatedExtendedResult<IProduct>>(`/parties/${partyId}/products`, {
      params: {page: page, size: size},
    })

    return data
  }

  public async getExternalContracts(
    partyId: any,
    page: number,
    size: number,
  ): Promise<IPaginatedExtendedResult<IExternalContract>> {
    const {data} = await this.httpClient.get<IPaginatedExtendedResult<IExternalContract>>(
      `/parties/${partyId}/external-contracts`,
      {
        params: {page: page, size: size},
      },
    )

    return data
  }

  public async getExternalContract(partyId: any, contractId: string): Promise<IExternalContract> {
    const {data} = await this.httpClient.get<IExternalContract>(`/parties/${partyId}/external-contracts/${contractId}`)
    return data
  }

  public async uploadAttachment(partyId: any, file: File): Promise<IAttachment> {
    const formData = new FormData()
    formData.append('file', file)
    formData.append('name', file.name)

    const {data} = await this.httpClient.post(`/parties/${partyId}/attachments`, formData)
    return data
  }

  public async saveExternalContract(partyId: any, contract: IExternalContractPayload): Promise<IExternalContract> {
    const {data} = await this.httpClient.post<IExternalContract>(`/parties/${partyId}/external-contracts`, contract)

    return data
  }

  public async getFinancialPerformance(
    partyId: any,
    startDate: string,
    endDate: string,
    interval: Interval,
  ): Promise<IFinancialPerformance> {
    const {data} = await this.httpClient.get<IFinancialPerformance>(
      `/parties/${partyId}/portfolio-overview/financial-performance/${interval}`,
      {
        params: {start: startDate, end: endDate},
      },
    )

    return data
  }

  public async getMarketListings(
    partyId: any,
    page: number,
    size: number,
  ): Promise<IPaginatedExtendedResult<IMarketListing>> {
    const {data} = await this.httpClient.get<IPaginatedExtendedResult<IMarketListing>>(
      `/parties/${partyId}/market-listings`,
      {
        params: {page: page, size: size},
      },
    )

    return data
  }

  public async getBasicProducts(partyId: any): Promise<IBasicProduct[]> {
    const {data} = await this.httpClient.get<IBasicProduct[]>(`/parties/${partyId}/products/basic`)
    return data
  }

  public async getBasicSites(partyId: any): Promise<IBasicSite[]> {
    const {data} = await this.httpClient.get<IBasicSite[]>(`/parties/${partyId}/sites/basic`)
    return data
  }

  public async createMarketListing(partyId: any, payload: INewMarketListing): Promise<boolean> {
    const {data} = await this.httpClient.post(`/parties/${partyId}/market-listings`, payload)

    return true
  }

  public async updateProduct(partyId: any, productId: string, payload: IUpdateProduct): Promise<IProduct> {
    const {data} = await this.httpClient.put(`/parties/${partyId}/products/${productId}`, payload)

    return data
  }

  public async getMarketListingsByProduct(
    partyId: any,
    productId: string,
    page: number,
    size: number,
  ): Promise<IPaginatedExtendedResult<IMarketListing>> {
    const {data} = await this.httpClient.get<IPaginatedExtendedResult<IMarketListing>>(
      `/parties/${partyId}/products/${productId}/market-listings`,
      {
        params: {page: page, size: size},
      },
    )

    return data
  }

  public async getBuyerProposals(
    partyId: any,
    page: number,
    size: number,
  ): Promise<IPaginatedExtendedResult<INewOffer>> {
    const {data} = await this.httpClient.get<IPaginatedExtendedResult<INewOffer>>(`/parties/${partyId}/buyer/offers`, {
      params: {page: page, size: size},
    })
    return data
  }

  public async getSellerProposals(partyId: any, page: number, size: number): Promise<INewSellerOfferPayload> {
    const {data} = await this.httpClient.get<INewSellerOfferPayload>(`/parties/${partyId}/seller/offers`, {
      params: {page: page, size: size},
    })
    return data
  }

  public async getMarketListingById(partyId: any, listingId: number): Promise<IMarketListing> {
    const {data} = await this.httpClient.get<IMarketListing>(`/parties/${partyId}/market-listings/${listingId}`)
    return data
  }

  public async getMarketListingCertReport(partyId: any, listingId: number): Promise<IMarketListingReport> {
    const {data} = await this.httpClient.get<IMarketListingReport>(
      `/parties/${partyId}/market-listings/${listingId}/cert-allocation-breakdown`,
    )
    return data
  }

  public async updateMarketListing(
    partyId: any,
    listingId: number,
    payload: IMarketListingUpdatePayload,
  ): Promise<IMarketListing> {
    const {data} = await this.httpClient.put<IMarketListing>(
      `/parties/${partyId}/market-listings/${listingId}`,
      payload,
    )
    return data
  }

  public async createBuyerSite(partyId: any, payload: ICreateBuyerSite): Promise<ISite> {
    const {data} = await this.httpClient.post<ISite>(`/parties/${partyId}/buyer/sites`, payload)
    return data
  }

  public async getBuyerSite(partyId: any, siteId: number): Promise<ISite> {
    const {data} = await this.httpClient.get<ISite>(`/parties/${partyId}/buyer/sites/${siteId}`)
    return data
  }

  public async updateBuyerSiteGeneral(partyId: any, siteId: number, payload: IUpdateBuyerSiteGeneral): Promise<ISite> {
    const {data} = await this.httpClient.put(`/parties/${partyId}/buyer/sites/${siteId}`, payload)
    return data
  }

  public async uploadBuyerSiteConsumption(
    partyId: any,
    siteId: number,
    payload: IUploadBuyerConsumption,
  ): Promise<ISite> {
    const {data} = await this.httpClient.post(`/parties/${partyId}/buyer/sites/${siteId}/timeseries`, payload)
    return data
  }

  public async getDetailedProfile(): Promise<IDetailedProfile> {
    const {data} = await this.httpClient.get<IDetailedProfile>(`/current-user/detailed-profile`)
    return data
  }

  public async generateOnboardingTestCertificates(partyId: any): Promise<ITestCertificates> {
    const {data} = await this.httpClient.post<ITestCertificates>(`/parties/${partyId}/seller/onboard/test-certificate`)
    return data
  }

  public async generateMarketListingTestCertificates(
    partyId: any,
    payload: IGenerateTestCertsPayload,
  ): Promise<ITestCertificates> {
    const {data} = await this.httpClient.post<ITestCertificates>(
      `/parties/${partyId}/market-listings/test-certificate`,
      payload,
    )
    return data
  }

  public async uploadExternalContractProduction(
    partyId: any,
    contractId: number,
    payload: IExternalContractUploadPayload,
  ): Promise<boolean> {
    const {data} = await this.httpClient.post(
      `/parties/${partyId}/external-contracts/${contractId}/timeseries`,
      payload,
    )
    return data
  }

  public async skipSellerOnboarding(partyId: any): Promise<boolean> {
    const {data} = await this.httpClient.post(`/parties/${partyId}/seller/onboard/skip`)
    return data
  }

  public async getBuyerKPIs(partyId: any, month: string, year: number): Promise<IKPI> {
    const {data} = await this.httpClient.get<IKPI>(`/parties/${partyId}/buyer/metrics`, {params: {month, year}})
    return data
  }

  public async getBuyerPartialKPIs(partyId: any, month: string, year: number): Promise<IKPI> {
    const {data} = await this.httpClient.get<IKPI>(`/parties/${partyId}/buyer/metrics/partial`, {params: {month, year}})
    return data
  }

  public async getKPITargets(partyId: any): Promise<{targets: IKPITargets}> {
    const {data} = await this.httpClient.get(`/parties/${partyId}/buyer/green-coverage/targets`)
    return data
  }

  public async createKPITargets(partyId: any, payload: IKPITargets): Promise<boolean> {
    const {data} = await this.httpClient.post<IKPITargets>(`/parties/${partyId}/buyer/green-coverage/targets`, payload)
    return true
  }

  public async updateKPITargets(partyId: any, payload: IKPITargets): Promise<IKPITargets> {
    const {data} = await this.httpClient.put<IKPITargets>(`/parties/${partyId}/buyer/green-coverage/targets`, payload)
    return data
  }

  public async createTenderRespond(partyId: any, payload: ITenderRespondCreatePayload): Promise<ITenderRespond> {
    const {data} = await this.httpClient.post<ITenderRespond>(`/parties/${partyId}/tender-estimates`, payload)
    return data
  }

  public async updateTenderRespond(partyId: any, id: number, payload: ITenderRespond): Promise<ITenderRespond> {
    const {data} = await this.httpClient.put<ITenderRespond>(`/parties/${partyId}/tender-estimates/${id}`, payload)
    return data
  }

  public async getTenderResponds(
    partyId: any,
    page: number,
    size: number,
  ): Promise<IPaginatedExtendedResult<ITenderRespond>> {
    const {data} = await this.httpClient.get<IPaginatedExtendedResult<ITenderRespond>>(
      `/parties/${partyId}/tender-estimates`,
      {
        params: {page: page, size: size},
      },
    )

    return data
  }

  public async getTenderRespond(partyId: any, id: number): Promise<ITenderRespond> {
    const {data} = await this.httpClient.get<ITenderRespond>(`/parties/${partyId}/tender-estimates/${id}`)
    return data
  }

  public async downloadAttachment(partyId: any, attachmentId: string): Promise<string> {
    const {data} = await this.httpClient.get(`/parties/${partyId}/attachments/${attachmentId}`, {responseType: 'blob'})
    const pdfBlob = new Blob([data], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'})
    const url = window.URL.createObjectURL(pdfBlob)

    return url
  }
}
