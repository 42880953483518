import {Skeleton} from '@mui/material'
import {rootServiceAtom} from 'atoms/general'
import {partyAtom} from 'atoms/party'
import {refetchSubscriptonAtom, subscriptionAtom, subscriptionIdAtom} from 'atoms/subscription'
import Box from 'components/Box'
import Button from 'components/Button'
import ConfirmationDialog from 'components/ConfirmationDialog'
import Page from 'components/Page'
import Text from 'components/Text'
import {CurrencyCode} from 'domain/IPrice'
import {BillingMode, SubscriptionStatus} from 'domain/ISubscription'
import {DateFormat, formatDate, isDateAfter} from 'helpers/date'
import {formatMoney} from 'helpers/format'
import {humanReadableSubscriptionStatus} from 'helpers/subscription'
import useLocalization from 'hooks/useLocalization'
import useTheme from 'hooks/useTheme'
import {useAtomValue, useSetAtom} from 'jotai'
import React, {useEffect, useState} from 'react'
import styled from 'styled-components'
import ITheme from 'theme/ITheme'
import {Status} from './SubscriptionList'

const StyledCard = styled.div`
  background-color: ${props => props.theme.colors.background};
  border-radius: 8px;
  padding: 24px;
  border: 1px solid ${props => (props.theme as ITheme).colors.lightGrey};
  max-height: 400px;
`

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
`

const Label = styled(Text)`
  font-weight: bold;
`

const Value = styled(Text)`
  text-align: right;
`

interface IProps {
  subscriptionId: number
}

const SubscriptionView: React.FC<IProps> = ({subscriptionId}) => {
  const {translate} = useLocalization()
  const {data: subscription, loading} = useAtomValue(subscriptionAtom)
  const rootService = useAtomValue(rootServiceAtom)
  const {v2ContentService} = rootService
  const party = useAtomValue(partyAtom)
  const [cancelModalOpen, setCancelModalOpen] = useState(false)
  const setSubscriptionId = useSetAtom(subscriptionIdAtom)
  const refetchSubscriptions = useSetAtom(refetchSubscriptonAtom)
  const theme = useTheme()

  useEffect(() => {
    if (subscriptionId) {
      setSubscriptionId(subscriptionId)
    }
  }, [subscriptionId])

  const handleCancel = async (subscription_id: number) => {
    await v2ContentService.cancelSubscription(party.id, subscription_id)
    refetchSubscriptions()
    setCancelModalOpen(false)
  }

  if (loading) {
    return (
      <Page title={translate('Subscription Details')} description={translate('View your subscription information')}>
        <Box pad={{vertical: 2, horizontal: 8}}>
          <StyledCard>
            {[...Array(7)].map((_, index) => (
              <Row key={index}>
                <Skeleton width="30%" height="24px" />
                <Skeleton width="40%" height="24px" />
              </Row>
            ))}
          </StyledCard>
          <Box pad={{vertical: 2}} align="center">
            <Skeleton width="200px" height="40px" />
          </Box>
        </Box>
      </Page>
    )
  }

  if (!subscription) {
    return <Text size="large">{translate('Subscription not found')}</Text>
  }

  return (
    <Page title={translate('Subscription Details')} description={translate('View your subscription information')}>
      <Box pad={{vertical: 2, horizontal: 8}}>
        <StyledCard>
          <Row>
            <Label>{translate('Status')}:</Label>
            <Status
              isActive={
                subscription.status === SubscriptionStatus.Active ||
                (subscription.status === SubscriptionStatus.PendingCancellation &&
                  isDateAfter(subscription.endsOn, new Date()))
              }
            >
              {humanReadableSubscriptionStatus(subscription.status)}
            </Status>
          </Row>
          {subscription.status === SubscriptionStatus.Canceled && (
            <Row>
              <Label>{translate('Ended at')}:</Label>
              <Value>{formatDate(subscription.endsOn, DateFormat.DAY_MONTH_YEAR)}</Value>
            </Row>
          )}
          {subscription.status === SubscriptionStatus.PendingCancellation && (
            <Row>
              <Label>{translate('Ends at')}:</Label>
              <Value>{formatDate(subscription.endsOn, DateFormat.DAY_MONTH_YEAR)}</Value>
            </Row>
          )}
          <Row>
            <Label>{translate('Plan')}:</Label>
            <Value>{subscription.plan}</Value>
          </Row>
          <Row>
            <Label>{translate('Amount')}:</Label>
            <Box direction="row" gap={1} align="center">
              <Text size="medium" bold font={theme.font.secondaryFont}>
                {formatMoney(subscription.amount)} {CurrencyCode[subscription.currencyCode]}
              </Text>
              <Text size="small" uppercase font={theme.font.secondaryFont} color={theme.colors.accent}>
                {subscription.billingMode === BillingMode.MONTHLY ? '/month' : '/year'}
              </Text>
            </Box>
          </Row>
          <Row>
            <Label>{translate('Next Billing Date')}:</Label>
            <Value>{formatDate(subscription.nextBillOn, DateFormat.DAY_MONTH_YEAR)}</Value>
          </Row>
          {subscription.billingMode === BillingMode.YEARLY && (
            <Row>
              <Label>{translate('Ends at')}:</Label>
              <Value>{formatDate(subscription.endsOn, DateFormat.DAY_MONTH_YEAR)}</Value>
            </Row>
          )}
          <Row>
            <Label>{translate('Payment Method')}:</Label>
            <Value>{subscription.paymentMethod}</Value>
          </Row>
          <Row>
            <Label>{translate('Start Date')}:</Label>
            <Value>{formatDate(subscription.startsOn, DateFormat.DAY_MONTH_YEAR)}</Value>
          </Row>
          <Row>
            <Label>{translate('Auto-renew')}:</Label>
            <Value>{subscription.billingMode === BillingMode.MONTHLY ? translate('Yes') : translate('No')}</Value>
          </Row>
          {subscription.status === SubscriptionStatus.Active && (
            <Box pad={{vertical: 2}} align="center">
              <Button onClick={() => setCancelModalOpen(true)}>{translate('Cancel Subscription')}</Button>
            </Box>
          )}
        </StyledCard>
      </Box>
      <ConfirmationDialog
        open={cancelModalOpen}
        setOpen={setCancelModalOpen}
        title={translate('Cancel subscription')}
        acceptText={translate('Cancel subscription')}
        declineText={translate('Close')}
        onAccept={() => handleCancel(subscription.id)}
        text={translate('Are you sure you want to cancel this subscription?')}
      />
    </Page>
  )
}

export default SubscriptionView
