import React from 'react'
import Box from 'components/Box'
import TechnologyIconAndName from 'components/Platform/TechnologyIconAndName'
import useLocalization from 'hooks/useLocalization'
import IProduct from 'domain/IProduct'
import KeyFeature from 'components/Platform/KeyFeature'
import {getStringifiedLocation} from 'helpers/product'
import ListItem, {Badge} from 'components/ListItem'
import Text from 'components/Text'
import useServices from 'hooks/useServices'
import {DateFormat, formatDate} from 'helpers/date'
import ProductCertProgress from './ProductCertProgress'
import useTheme from 'hooks/useTheme'
import {formatDecimal} from 'helpers/format'

interface IProductDetailsProps {
  product: IProduct
  quantity: number
}

const ProductDetails: React.FC<IProductDetailsProps> = ({product, quantity}) => {
  const {translate} = useLocalization()
  const theme = useTheme()
  const {contentService} = useServices()
  const {site} = product || {}
  const operationsStart = site?.operationsStart
  const operationsEnd = site?.operationsEnd
  const productionTechnology = site?.productionTechnology

  if (!product) return null

  return (
    <Box>
      <ListItem
        key={product.id}
        to={''}
        noHover
        noPanel
        title={product?.name}
        onClick={e => e.preventDefault()}
        description={getStringifiedLocation(product, translate)}
        defaultCoverColor={theme.colors.technology[productionTechnology]}
        coverContent={
          <>
            <Badge>
              <TechnologyIconAndName technology={productionTechnology} />
            </Badge>
          </>
        }
        coverImage={contentService.getAttachmentURL(product.image)}
      />
      <Box pad={{left: 2}}>
        <Box direction="row" gap={4} pad={{left: 1, top: 1}}>
          <KeyFeature title={translate('Start of operations')}>
            <Text size="large">{operationsStart ? formatDate(operationsStart, DateFormat.MONTH_YEAR) : '-'}</Text>
          </KeyFeature>
          <KeyFeature title={translate('End of operations')}>
            <Text size="large">{operationsEnd ? formatDate(operationsEnd, DateFormat.MONTH_YEAR) : '-'}</Text>
          </KeyFeature>
          <KeyFeature title={translate('Total volume for sale')}>
            <Text font={theme.font.numbers} size="large">
              {formatDecimal(product.totalQuantity, 0)}
            </Text>
          </KeyFeature>
        </Box>
        <Box width="40%">
          <ProductCertProgress
            quantity={quantity}
            totalSold={product.soldQuantity}
            total={product.totalQuantity}
            reserved={product.reservedQuantity}
          />
        </Box>
      </Box>
    </Box>
  )
}

export default ProductDetails
