import React from 'react'
import useFormContext from '../../hooks/useFormContext'
import Button, {IProps as IButtonProps} from '../Button'
import styled from 'styled-components'
import Text from '../Text'
import Box from '../Box'
import ITheme from '../../theme/ITheme'
import useLocalization from '../../hooks/useLocalization'

interface IProps extends React.PropsWithChildren, IButtonProps {
  error?: string
  buttonId?: number
  variant?: 'primary' | 'secondary' | 'clear' | 'outlined'
}

const Error = styled.div`
  display: flex;
  align-items: center;
  color: ${props => (props.theme as ITheme).colors.common.white};
  border-radius: 4px;
  padding: ${props => (props.theme as ITheme).tileSize * 0.5}px ${props => (props.theme as ITheme).tileSize}px;
  white-space: nowrap;
  box-sizing: border-box;
  background-color: ${props => (props.theme as ITheme).colors.error};
  margin-bottom: ${props => (props.theme as ITheme).tileSize}px;

  svg {
    fill: ${props => (props.theme as ITheme).colors.common.white};
    margin-right: 5px;
  }
`

const FormSubmitButton: React.FC<IProps> = ({error: suppliedError, margin, children, buttonId, ...buttonProps}) => {
  const {translate} = useLocalization()
  const {
    formState: {isSubmitting},
    errors,
    setMeta,
    meta,
  } = useFormContext()
  const error = suppliedError === undefined ? (errors.generic as any)?.message : suppliedError

  return (
    <Box margin={margin}>
      {error && (
        <Error>
          <Text>{error}</Text>
        </Error>
      )}
      <Button
        onClick={() => setMeta({...meta, submitButtonId: buttonId})}
        type="submit"
        disabled={isSubmitting}
        {...buttonProps}
      >
        {isSubmitting ? translate('Please wait..') : children}
      </Button>
    </Box>
  )
}

export default FormSubmitButton
