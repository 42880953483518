import {atom, PrimitiveAtom} from 'jotai'
import {atomWithCompare, loadable} from './utils'
import {partyAtom} from './party'
import {intervalAtom, rootServiceAtom} from './general'
import {IFilter} from 'components/Platform/Buy/CertificateDesigner/GeneralFilter'
import RootService from 'services'
import {monthAtom, yearAtom} from './marketplace'
import {atomWithRefresh} from 'jotai/utils'

const baseFilter: IFilter = {
  dayOfMonth: 1,
  quantity: 50,
}

export const productIdAtom = atom<string>(null) as PrimitiveAtom<string>
export const offerIdAtom = atom<string>(null) as PrimitiveAtom<string>
export const filterAtom = atomWithCompare<IFilter>(baseFilter)

export const productAtom = loadable(
  atom(async get => {
    const services: RootService = get(rootServiceAtom)
    const party = get(partyAtom)
    const productId = get(productIdAtom)
    const year = +get(yearAtom)
    const month = get(monthAtom)

    if (productId === null) return null

    const product = await services.v2ContentService.getCertificateProduct(party.id, productId, month, year)

    return product
  }),
  {nullIsLoading: true},
)

export const proposalDetailsAtom = loadable(
  atom(async get => {
    const services = get(rootServiceAtom)
    const filter = get(filterAtom)
    const interval = get(intervalAtom)
    const party = get(partyAtom)
    const productId = get(productIdAtom)
    const year = +get(yearAtom)
    const month = get(monthAtom)

    if (productId === null) return null

    const data = await services.v2ContentService.getCertificateProposalDetails(
      party.id,
      {
        ...filter,
        year: year,
        month: month,
        product: {productId: +productId, quantity: filter.quantity},
      },
      interval,
    )

    return data
  }),
  {nullIsLoading: true},
)

export const offerAtom = loadable(
  atomWithRefresh(async get => {
    const services: RootService = get(rootServiceAtom)
    const party = get(partyAtom)
    const offerId = get(offerIdAtom)

    if (offerId === null) return null

    const offer = await services.v2ContentService.getCertificatePurchaseOffer(party.id, +offerId)

    return offer
  }),
  {nullIsLoading: true},
)

export const resetAtom = atom(null, (_, set) => {
  set(productIdAtom, null)
  set(offerIdAtom, null)
  set(filterAtom, {...baseFilter})
})
