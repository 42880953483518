import {PartyRole} from '../domain/IParty'
import {Country} from '../domain/ILocation'
import {PriceUnit} from '../domain/IPrice'
import {VolumeUnit} from '../domain/IContractItem'
import {SiteProductionTechnology, SiteShape} from '../domain/ISite'
import {ISpacingProps} from '../components/styleProps/spacing'

export interface IColor {
  main: string
  light: string
  dark: string
}

export interface IColors {
  primary: string
  primaryLight: string
  primaryDark: string
  secondary: string
  common: {
    black: string
    white: string
  }
  accent: string
  outline: string
  error: string
  info: string
  success: string
  warning: string
  toned: string
  lightGrey: string
  surface: string
  clay: string
  limeGreen: string
  green0: string
  green1: string
  green2: string
  green3: string
  green4: string
  green5: string
  green6: string
  green7: string
  yellow0: string
  yellow1: string
  yellow2: string
  yellow3: string
  yellow4: string
  yellow5: string
  yellow6: string
  yellow7: string
  blue0: string
  blue1: string
  blue2: string
  blue3: string
  blue4: string
  blue5: string
  blue6: string
  blue7: string
  grey0: string
  grey1: string
  grey2: string
  grey3: string
  grey4: string
  grey5: string
  grey6: string
  grey7: string
  nGrey0: string
  nGrey1: string
  nGrey2: string
  nGrey3: string
  nGrey4: string
  nGrey5: string
  nGrey6: string
  nGrey7: string
  globalBackground: string
  contrastBackground: string
  light1: string
  light2: string
  light3: string
  light4: string
  technology: {
    [key in SiteProductionTechnology]: string
  }
  siteShape: {
    [key in SiteShape]: string
  }
}

interface IColorBindings {
  elementsAccent: keyof IColors
  textOnBrandedBackground: keyof IColors
  userBadgeBackground: keyof IColors
  globalBackground?: keyof IColors
}

interface IIcon {
  width: number
  height: number
  borderSize: number
}

interface IFont {
  primaryFont: string
  secondaryFont?: string
  headings?: string
  numbers?: string
}

interface ILogo {
  logo: string
  greenLogo: string
  spacing: ISpacingProps
  height: number
}

interface ILoginText {
  heading1: string
  heading2: string
  heading3: string
  iconTitle1: string
  iconText1: string
  icon1: string
  iconTitle2: string
  iconText2: string
  icon2: string
  iconTitle3: string
  iconText3: string
  icon3: string
  iconTitle4: string
  iconText4: string
  icon4: string
}

export type ILanguages = 'en' | 'nl'

export enum OfferPriceRange {
  RANGE = 'RANGE',
  FIXED = 'FIXED',
}

interface ICover {
  image: string
  position?: string
  size?: string
}

export default interface ITheme {
  wrapHeader: boolean
  tileSize: number
  colors: IColors
  title: string
  logo: ILogo
  favicon?: string
  poweredBy?: boolean
  renewablLogo: string
  heroImage?: string
  icon: IIcon
  font: IFont
  topNavigationFontSize: number
  roundness: number
  colorBindings: IColorBindings
  colorBindingsByRole: {[key in PartyRole]?: Partial<IColorBindings>}
  countries: Country[]
  priceUnit: PriceUnit
  volumeUnit: VolumeUnit
  offer: {
    hasRiskAndOperationalFocus: boolean
    hasConfigurablePriceParameters: boolean
    showPriceInCentsPerKwh: boolean
    showPreviewGreenIndex: boolean
    showPreviewTermsAndConditions: boolean
    dutchCooAlternative?: string
  }
  product: {
    hidePriceInList: boolean
  }
  sites: {
    showAddOfferButton: boolean
    showAddCustomerButton: boolean
  }
  loginRedirectPaths: {[key in PartyRole]?: string}
  map: {
    defaultCenter: [number, number]
    defaultZoom: number
    maxZoom: number
  }
  charts: {
    showActualConsumptionVolumes: boolean
    height: number
  }
  loginText: ILoginText
  button: {
    border: boolean
    hover: string
  }
  languageSelect: ILanguages[]
  lgcEquivalent?: string
  firmingEquivalent?: string
  offerPriceRange: OfferPriceRange
  showPriceInOffer: boolean
  nmiEquivalent?: string
  numberDecimals: number
  emissionFactorDefault: number
  retailerPartyName?: string
}
