import FormSelect from 'components/Form/FormSelect'
import IManagedPartyMember from 'domain/IManagedPartyMember'
import {PartyMemberRole} from 'domain/PartyMemberRole'
import {isPartyAdmin, stringifyMemberRole} from 'helpers/partyMember'
import React from 'react'
import useLocalization from '../../../hooks/useLocalization'
import Form from '../../Form'
import FormInput from '../../Form/FormInput'
import FormSubmitButton from '../../Form/FormSubmitButton'
import Heading from '../../Heading'
import Box from 'components/Box'
import useTheme from 'hooks/useTheme'

interface IProps extends React.PropsWithChildren {
  partyMember: IManagedPartyMember
  handleSubmit: (data: any) => void
}

export interface IFormData {
  firstName: string
  lastName: string
  email: string
  role: PartyMemberRole
}

const EditManagedPartyMemberForm: React.FC<IProps> = ({partyMember, handleSubmit}) => {
  const {translate} = useLocalization()
  const theme = useTheme()

  return (
    <Form onSubmit={(data: IFormData) => handleSubmit(data)} submissionFeedback={null}>
      <Box pad={2} round border width="50%">
        <Heading pad={{vertical: 1}} size="small">
          {translate('Personal information')}
        </Heading>
        <FormInput
          label={translate('First name')}
          name="firstName"
          required
          defaultValue={partyMember.user.firstName}
        />
        <FormInput label={translate('Surname')} name="lastName" defaultValue={partyMember.user.lastName} required />
        <FormInput label={translate('Email address')} name="email" defaultValue={partyMember.user.email} disabled />
        <FormSelect
          label={translate('Role')}
          name="role"
          defaultValue={isPartyAdmin(partyMember) ? PartyMemberRole.PARTY_ADMIN : PartyMemberRole.PARTY_USER}
          options={Object.values(PartyMemberRole).map(memberRole => ({
            value: memberRole,
            label: stringifyMemberRole(memberRole, translate),
          }))}
        ></FormSelect>

        <FormSubmitButton margin={{top: 1}}>{translate('Save')}</FormSubmitButton>
      </Box>
    </Form>
  )
}

export default EditManagedPartyMemberForm
