import IPurchaseContract from 'domain/IPurchaseContract'
import {action, computed} from 'mobx'
import IParty from '../domain/IParty'
import {ProductState} from '../domain/IProduct'
import {VolumeType} from '../domain/IProductType'
import {hasMeta, withMeta} from '../helpers/misc'
import IContentService from '../services/IContentService'
import {ResourceId, WithMeta} from '../types'
import RootStore from './index'
import ResourceStore, {RelationType} from './ResourceStore'

interface IMeta {
  partyId?: ResourceId
  purchase?: boolean
}

export default class PurchaseContractStore extends ResourceStore<WithMeta<IPurchaseContract, IMeta>> {
  private contentService: IContentService
  private rootStore: RootStore

  public constructor(rootStore: RootStore, contentService: IContentService) {
    super([
      {
        type: RelationType.OneToMany,
        inversedBy: 'contract',
        getStore: () => rootStore.contractItemStore,
        key: 'contractItems',
      },
    ])
    this.rootStore = rootStore
    this.contentService = contentService
  }

  @action
  public async loadPartyPurchaseContracts() {
    await this.setManyEventually(
      this.contentService
        .getPurchaseContracts(this.party.id)
        .then(contracts => contracts.map(contract => withMeta(contract, {partyId: this.party.id, purchase: true}))),
    )
  }

  public async loadPartySalesContracts() {
    await this.setManyEventually(
      this.contentService
        .getSalesContracts(this.party.id)
        .then(contracts => contracts.map(contract => withMeta(contract, {partyId: this.party.id, purchase: false}))),
    )
  }

  @computed
  public get partySalesContracts(): IPurchaseContract[] {
    return this.items.filter(contract => hasMeta(contract, meta => meta.partyId === this.party.id && !meta.purchase))
  }

  @action
  public async savePurchaseContract(contract: IPurchaseContract): Promise<IPurchaseContract> {
    const partyId = this.party.id

    const productTypes = await this.contentService.getProductTypes(this.party.id)
    const productType = productTypes.find(pt => pt.volumeType === VolumeType.USER_SELECT)

    if (!productType) {
      throw new Error('No suitable product type')
    }

    const parsedContract: IPurchaseContract = {
      ...contract,
      contractItems: contract.contractItems.map(contractItem => ({
        ...contractItem,
        volumeMwh: contract.volumeMwh,
        product: {
          ...contractItem.product,
          name: contractItem.product.site.name,
          productType,
          state: ProductState.IN_OPERATION,
          // TODO: This hack is necessary to display a chart
          totalVolumeForSaleMwh: 9999999999,
        },
      })),
    }

    return await this.setOneEventually(
      contract.id
        ? this.contentService.updatePurchaseContract(partyId, parsedContract)
        : this.contentService.createPurchaseContract(partyId, parsedContract),
    )
  }

  @computed
  public get partyPurchaseContracts(): IPurchaseContract[] {
    return this.items.filter(contract => hasMeta(contract, meta => meta.partyId === this.party.id && meta.purchase))
  }

  @computed
  private get party(): IParty {
    return this.rootStore.profileStore.party
  }
}
