import ResourceStore from './ResourceStore'
import IContentService from '../services/IContentService'
import {computed} from 'mobx'
import RootStore from './index'
import IParty from '../domain/IParty'
import IOfferRequest from '../domain/IOfferRequest'

export default class OfferRequestStore extends ResourceStore<IOfferRequest> {
  private readonly rootStore: RootStore
  private readonly contentService: IContentService

  public constructor(rootStore: RootStore, contentService: IContentService) {
    super([])
    this.rootStore = rootStore
    this.contentService = contentService
  }

  public async loadCurrentPartyOfferRequests() {
    await this.setManyEventually(this.getPartyOfferRequests())
  }

  private async getPartyOfferRequests() {
    return await this.contentService.getPartyOfferRequests(this.party.id)
  }

  @computed
  private get party(): IParty {
    return this.rootStore.profileStore.party
  }

  @computed
  public get offerRequests(): IOfferRequest[] {
    return this.items
  }
}
