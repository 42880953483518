import {action, observable} from 'mobx'
import INewTimeseriesItem, {SellExcessPayload} from '../domain/INewTimeseriesItem'
import IContentService from '../services/IContentService'
import {ResourceId} from '../types'
import dayjs from 'dayjs'
import {SiteProductionTechnology} from 'domain/ISite'
import {IRecommendedExcessPrice} from 'domain/ISellExcessRequest'
import IV2ContentService from 'services/IV2ContentService'

enum Type {
  GREEN_CERTIFICATES_CONSUMPTION,
}

export default class TimeseriesStore {
  private contentService: IContentService
  private v2ContentService: IV2ContentService

  @observable public data: {[key: string]: INewTimeseriesItem} = {}
  @observable public errors: {[key: string]: string} = {}
  @observable public year: number = dayjs().year()
  @observable public month: string = dayjs().subtract(1, 'month').format('MMMM')
  @observable public dayOfMonth?: number
  @observable public excessPrice?: IRecommendedExcessPrice
  @observable public loading: boolean = false

  public constructor(contentService: IContentService, v2ContentService: IV2ContentService) {
    this.contentService = contentService
    this.v2ContentService = v2ContentService
  }

  public getData(key: string): INewTimeseriesItem {
    return this.data[key]
  }

  @action
  public setData(key: string, data: INewTimeseriesItem): void {
    this.data[key] = data
  }

  @action
  public setYear(year: number): void {
    this.year = year
  }

  @action
  public setMonth(month: string): void {
    this.month = month
  }

  @action
  public setDayOfMonth(day: number): void {
    this.dayOfMonth = day
  }

  public async loadGreenCertificatesConsumption(partyId: ResourceId) {
    this.loading = true
    await this.setEventually(
      this.getKey(Type.GREEN_CERTIFICATES_CONSUMPTION, partyId),
      this.v2ContentService.getGreenCertificatesData(partyId, this.year, this.month, this.dayOfMonth),
    )
    this.loading = false
  }

  @action
  public async sellExcessRequest(partyId: ResourceId, payload: SellExcessPayload): Promise<boolean> {
    try {
      await this.contentService.sellCertExcessRequest(partyId, payload)

      return true
    } catch {
      return false
    }
  }

  @action
  public async getRecommendedExcessPrice(
    partyId: ResourceId,
    technologyKind: SiteProductionTechnology,
  ): Promise<IRecommendedExcessPrice> {
    try {
      const result = await this.contentService.getRecommendedExcessPrice(partyId, technologyKind)

      return result
    } catch {
      console.error('Failed to get recommended excess price')
      return null
    }
  }

  public getGreenCertificatesConsumption(partyId: ResourceId) {
    return this.getData(this.getKey(Type.GREEN_CERTIFICATES_CONSUMPTION, partyId))
  }

  public getGreenCertificatesConsumptionError(partyId: ResourceId) {
    return this.errors[this.getKey(Type.GREEN_CERTIFICATES_CONSUMPTION, partyId)] || null
  }

  private async setEventually(key: string, promise: Promise<INewTimeseriesItem>) {
    try {
      const data = await promise

      this.errors[key] = null
      this.setData(key, data)
    } catch (e) {
      this.errors[key] = e.message
    }
  }

  private getKey(type: Type, resourceId: ResourceId): string {
    return [type, resourceId, this.year, this.month, this.dayOfMonth].join('/')
  }
}
