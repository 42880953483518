import React, {useState} from 'react'
import useLocalization from '../../../../hooks/useLocalization'
import Page from '../../../Page'
import Button from '../../../Button'
import SiteMap from '../../Map/SiteMap'
import InnerScroll from '../../../InnerScroll'
import Link from '../../../Link'
import Box from '../../../Box'
import {useRouteMatch} from 'react-router'
import {SiteType} from '../../../../domain/ISite'
import {ISubscriptionPlan, PartyRole} from '../../../../domain/IParty'
import {useAtomValue, useAtom} from 'jotai'
import {partyAtom} from 'atoms/party'
import {dashboardAtom, pageAtom, pageSizeAtom, passedPartyAtom, sitesAtom} from 'atoms/portfolioSites'
import {PaginateWithSelection} from 'components/Paginate/Paginate'
import SiteListNew from './SiteListNew'
import DashboardChart from './DashboardChart'
import DashboardLoadingSkeleton from './DashboardLoadingSkeleton'
import ErrorInfo from 'components/ErrorInfo'
import {usePaywall} from 'hooks/usePaywall'

const RESULT_PER_PAGE_OPTIONS = [25, 50, 100]

const SiteListPage: React.FC<React.PropsWithChildren> = () => {
  const {translate} = useLocalization()
  const [selectedSites, setSelectedSites] = useState<number[]>([])
  const currentParty = useAtomValue(partyAtom)
  const passedParty = useAtomValue(passedPartyAtom)
  const party = passedParty || currentParty
  const [page, setPage] = useAtom(pageAtom)
  const [pageSize, setPageSize] = useAtom(pageSizeAtom)
  const {data: sitesResult, loading: sitesLoading, error: sitesError} = useAtomValue(sitesAtom)
  const {data: dashboardResult, loading: dashboardLoading, error: dashboardError} = useAtomValue(dashboardAtom)
  const siteType = party?.partyRole === PartyRole.BUYER ? SiteType.CONSUMPTION : SiteType.PRODUCTION
  const {url: urlPrefix} = useRouteMatch()
  const error = sitesError || dashboardError
  const {openPaywall} = usePaywall()

  const handleSelectSite = (siteId: number) => {
    if (selectedSites.includes(siteId)) {
      setSelectedSites(prev => prev.filter(id => id !== siteId))
    } else {
      setSelectedSites(prev => [siteId, ...prev])
    }
  }

  const handleSelectAll = () => {
    if (selectedSites.length === sitesResult?.data.length) {
      setSelectedSites([])
    } else {
      setSelectedSites(sitesResult?.data.map(site => site.id) || [])
    }
  }

  if (dashboardLoading || sitesLoading) {
    return (
      <Page
        title={siteType === SiteType.CONSUMPTION ? translate('Consumption') : translate('Sites')}
        description={translate('Manage your sites')}
      >
        <Box pad={{top: 2}}>
          <DashboardLoadingSkeleton />
        </Box>
      </Page>
    )
  }

  return (
    <Page
      title={siteType === SiteType.CONSUMPTION ? translate('Consumption') : translate('Sites')}
      description={translate('Manage your sites')}
      corner={
        <Box gap={1}>
          {party?.subscriptionPlan === ISubscriptionPlan.BASIC ? (
            <Button onClick={() => openPaywall()}>{translate('Add new site')}</Button>
          ) : (
            <Link to={`${urlPrefix}/add`}>
              <Button>{translate('Add new site')}</Button>
            </Link>
          )}
        </Box>
      }
      aside={
        <SiteMap
          title={siteType === SiteType.CONSUMPTION ? translate('Consumption') : translate('Sites')}
          description={translate('Overview of your sites locations')}
          sites={sitesResult?.data || []}
          urlPrefix={`${urlPrefix}/edit`}
        />
      }
      error={error && <ErrorInfo error={error} title={translate('Failed to load dashboard')} />}
    >
      <InnerScroll noRightPad>
        <Box pad={{top: 2}}>
          <DashboardChart
            timeseries={dashboardResult}
            selectedSites={selectedSites}
            setSelectedSite={handleSelectSite}
          />
        </Box>

        <SiteListNew
          sites={sitesResult?.data}
          siteType={siteType}
          isLoading={sitesLoading}
          selectedSites={selectedSites}
          setSelectedSite={handleSelectSite}
          setAll={handleSelectAll}
        />
      </InnerScroll>
      <Box align="flex-end" justify="center">
        <PaginateWithSelection
          activePage={page}
          resultsPerPage={pageSize}
          resultsPerPageOptions={RESULT_PER_PAGE_OPTIONS}
          totalPages={sitesResult?.totalPages || 1}
          handlePageChange={value => setPage(value)}
          handleResultsPerPageChange={value => {
            setPageSize(value)
            setPage(1)
          }}
        />
      </Box>
    </Page>
  )
}

export default SiteListPage
