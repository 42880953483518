import React from 'react'
import useLocalization from 'hooks/useLocalization'
import useTheme from 'hooks/useTheme'
import Label from 'components/Label'
import ProgressWithLegend from 'components/ProgressWithLegend'

interface IProductCertProgressProps {
  quantity?: number
  totalSold: number
  total?: number
  reserved?: number
}

const ProductCertProgress: React.FC<IProductCertProgressProps> = ({quantity, totalSold, total, reserved}) => {
  const {translate} = useLocalization()
  const theme = useTheme()
  const totalOnHold = totalSold + reserved
  const available = total - totalOnHold
  const hasSold = totalSold && totalSold > 0

  const fills: Array<{color: string; text: string; value: number; opacity?: number}> = [
    quantity && {
      color: theme.colors.grey2,
      text: translate('My reserved volume'),
      value: Math.round(quantity),
    },
    hasSold && {
      color: theme.colors.yellow2,
      text: translate('Sold'),
      value: Math.round(totalSold),
    },
    reserved && {
      color: theme.colors.info,
      text: translate('Reserved'),
      value: Math.round(reserved),
    },
    total && {
      color: theme.colors.green0,
      text: translate('Available'),
      value: Math.round(available),
      opacity: 0.8,
    },
  ].filter(Boolean)

  return (
    <Label text={translate('Sales progress')}>
      <ProgressWithLegend fills={fills} max={total || totalOnHold} />
    </Label>
  )
}

export default ProductCertProgress
