import React, {useState} from 'react'
import GenericLayout, {Spacer} from '../../Layout'
import TopNavigation, {TopNavigationLink} from '../../Layout/TopNavigation'
import UserBadge from './UserBadge'
import AsideNavigation, {AsideNavigationLink} from './AsideNavigation'
import IRoute from '../../../interfaces/IRoute'
import PartySelect from './PartySelect'
import Box from 'components/Box'
import UserMenu from './UserMenu'
import ConfirmationDialog from 'components/ConfirmationDialog'
import {useHistory} from 'react-router-dom'

interface IProps extends React.PropsWithChildren {
  topNavigation: IRoute[]
  asideNavigation: IRoute[]
}

const Layout: React.FC<IProps> = ({topNavigation, asideNavigation, children}) => {
  const history = useHistory()
  const [dialogProps, setDialogProps] = useState({open: false, title: '', destination: ''})

  const handleTopNavigationClick = (
    e: React.MouseEvent<HTMLAnchorElement>,
    dialogProps: {title: string; destination: string},
  ) => {
    if (dialogProps?.title) {
      e.preventDefault()
      setDialogProps({open: true, ...dialogProps})
    }
  }

  return (
    <GenericLayout
      top={
        <TopNavigation>
          {topNavigation.map(item => (
            <TopNavigationLink
              key={item.path}
              to={item.path}
              onClick={e => handleTopNavigationClick(e, {title: item.confirmationText, destination: item.path})}
            >
              {item.title}
            </TopNavigationLink>
          ))}
          <Spacer />
          <PartySelect />
          <UserMenu />
          <ConfirmationDialog
            title={dialogProps.title}
            acceptText="Continue"
            declineText="Back"
            onAccept={() => {
              history.push(dialogProps.destination)
              setDialogProps({...dialogProps, open: false})
            }}
            open={dialogProps.open}
            setOpen={open => setDialogProps({...dialogProps, open})}
          />
        </TopNavigation>
      }
      aside={
        <>
          <Box pad={{top: 4}}>
            <UserBadge />
          </Box>
          <AsideNavigation>
            {asideNavigation.map(item => (
              <AsideNavigationLink key={item.path} to={item.path}>
                {item.title}
              </AsideNavigationLink>
            ))}
          </AsideNavigation>
        </>
      }
    >
      {children}
    </GenericLayout>
  )
}

export default Layout
