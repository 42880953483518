import React from 'react'
import Chart, {ChartType, getMaxValue, getMinValue, ILine, StackType} from '../../../Chart'
import {autoCeilNumber, autoFloorNumber, getChangeEventValue} from '../../../../helpers/misc'
import {IProposalTimeseries} from 'domain/IProposalDetails'
import useTheme from 'hooks/useTheme'
import Input from 'components/Input'
import {useAtom, useAtomValue} from 'jotai'
import {filterAtom} from 'atoms/certificateDesigner'
import useLocalization from 'hooks/useLocalization'
import {monthAtom, yearAtom} from 'atoms/marketplace'
import {intervalAtom} from 'atoms/general'
import {Interval} from 'services/IContentService'
import useNewChartColors from 'hooks/useNewChartColors'
import {capitalize} from 'lodash-es'

interface IProps extends React.PropsWithChildren {
  timeseries: IProposalTimeseries
  hideMissing?: boolean
  technology?: string
}

const getLineLabel = (key: string) => {
  if ('missing' === key) {
    return 'Shortfall'
  }

  return capitalize(key)
}

const ProposalDetailsChart: React.FC<IProps> = ({timeseries, hideMissing, technology}) => {
  const chartColors = useNewChartColors()
  const {translate} = useLocalization()
  const theme = useTheme()
  const [filter, setFilter] = useAtom(filterAtom)
  const month = useAtomValue(monthAtom)
  const year = useAtomValue(yearAtom)
  const interval = useAtomValue(intervalAtom)

  if (!timeseries) {
    return null
  }

  const labels = timeseries.missing.map(({name}) => name) || []
  const {product, missing} = timeseries
  const productColor = technology && theme.colors.technology[technology]

  const lines: ILine[] = []
  const lineKeys = {missing: missing}

  if (hideMissing) {
    delete lineKeys['missing']
  }

  lines.push(
    ...Object.keys(lineKeys).map((key, index) => ({
      key: key,
      label: getLineLabel(key),
      color: theme.colors.blue7,
      data: lineKeys[key].map(({value}) => value),
      type: ChartType.LINE,
      stackType: StackType.SECONDARY,
      fill: false,
      showPoint: true,
    })),
  )
  lines.push({
    key: product.productName,
    label: product.productName,
    color: productColor || chartColors.getNext(),
    data: product?.timeseries.map(({value}) => value),
    type: ChartType.LINE,
    stackType: StackType.STACKED,
    fill: true,
  })

  const handleDayChange = e => {
    const value = getChangeEventValue(e)

    setFilter({...filter, dayOfMonth: value})
  }

  return (
    <Chart
      toolbar={{
        vertical: true,
        timescale: {hideYearly: true, hideMonthly: true},
        customToolbar: (
          <Input
            disabled={interval !== Interval.HOURLY}
            onChange={handleDayChange}
            label={translate('Day of month')}
            value={{day: filter.dayOfMonth, month: month, year: year}}
            type="day"
          />
        ),
      }}
      labels={labels}
      lines={lines}
      dark
      maxValue={autoCeilNumber(getMaxValue(lines))}
      minValue={autoFloorNumber(getMinValue(lines))}
    />
  )
}

export default ProposalDetailsChart
