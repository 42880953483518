import {action, computed, observable} from 'mobx'
import IProduct from '../domain/IProduct'
import IParty from '../domain/IParty'

export interface IShoppingCart {
  product: IProduct
  volume: number
}

export default class ShoppingCartStore {
  @observable
  private _cart: IShoppingCart[] = []
  @observable
  private _party: IParty
  private _clearCartOnSave: boolean

  @action
  public addProduct(product: IProduct, volume: number): void {
    this._cart = [...this._cart, {product, volume}]
  }

  @action
  public removeProduct(productId: number): void {
    this._cart = this._cart.filter(item => item.product.id !== productId)
  }

  @action
  public clearCart(): void {
    this._cart = []
  }

  @computed
  public get cart(): IShoppingCart[] {
    return this._cart
  }

  @action
  public setParty(party: IParty): void {
    this._party = party
  }

  @computed
  public get party(): IParty {
    return this._party
  }

  public setClearCartOnSave(bool: boolean): void {
    this._clearCartOnSave = bool
  }

  public get clearCartOnSave(): boolean {
    return this._clearCartOnSave
  }
}
