import React from 'react'
import ResourceGateway from '../../../components/ResourceGateway'
import SalesContractListPage from '../../../components/Platform/Portfolio/SalesContract/SalesContractListPage'
import SalesContractDetails from '../../../components/Platform/Portfolio/ConnectedSalesContractPage'

const SalesContracts: React.FC<React.PropsWithChildren> = () => {
  return (
    <ResourceGateway
      single={routeProps => <SalesContractDetails id={routeProps.match.params.id} editable={false} />}
      list={() => <SalesContractListPage />}
    />
  )
}

export default SalesContracts
