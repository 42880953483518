import {ISignedContractFile} from 'domain/ISignedContractFile'
import {action, computed, observable} from 'mobx'
import IContract, {ContractState} from '../domain/IContract'
import IParty from '../domain/IParty'
import {hasMeta, withMeta} from '../helpers/misc'
import IContentService from '../services/IContentService'
import {ResourceId, WithMeta} from '../types'
import RootStore from './index'
import ResourceStore, {RelationType} from './ResourceStore'
import IV2ContentService from 'services/IV2ContentService'

interface IMeta {
  partyId?: ResourceId
  purchase?: boolean
}

export default class ContractStore extends ResourceStore<WithMeta<IContract, IMeta>> {
  private contentService: IContentService
  private v2ContentService: IV2ContentService
  private rootStore: RootStore
  @observable private files: ISignedContractFile[]

  public constructor(rootStore: RootStore, contentService: IContentService, v2ContentService: IV2ContentService) {
    super([
      {
        type: RelationType.OneToMany,
        inversedBy: 'contract',
        getStore: () => rootStore.contractItemStore,
        key: 'contractItems',
      },
    ])
    this.rootStore = rootStore
    this.contentService = contentService
    this.v2ContentService = v2ContentService
  }

  public async loadPartyContracts() {
    await this.setManyEventually(
      this.contentService
        .getPartyContracts(this.party?.id)
        .then(contracts => contracts.map(contract => withMeta(contract, {partyId: this.party.id}))),
    )
  }

  public async loadDraftContractsWithoutOffer() {
    await this.clearItems()

    await this.setManyEventually(
      this.contentService
        .getDraftContractsWithoutOffer(this.party.id)
        .then(contracts => contracts.map(contract => withMeta(contract, {partyId: this.party.id}))),
    )
  }

  public async loadContractSnapshot(id: ResourceId) {
    await this.setOneEventually(this.contentService.getContractSnapshot(id, this.party.id))
  }

  @action
  public async signContract(contractId: ResourceId) {
    await this.v2ContentService.signContract(this.party.id, contractId)

    await this.loadPartyContracts()
  }

  @action
  public async uploadSignedContractFiles(offerId: number, files: File[]) {
    await this.contentService.uploadSignedContractFiles(offerId, files)
  }

  @action
  public async deleteSignedContractFile(contractId: number, fileId: number) {
    await this.contentService.deleteSignedContractFile(contractId, fileId)
  }

  @computed
  public get partyContracts(): IContract[] {
    return this.items.filter(contract => hasMeta(contract, meta => meta.partyId === this.party.id))
  }

  @computed
  public get partyContractsWithNoOffer(): IContract[] {
    return this.items.filter(contract => hasMeta(contract, meta => meta.partyId === this.party.id))
  }

  @computed
  public get partyDraftContracts(): IContract[] {
    return this.partyContracts.filter(contract => contract.state === ContractState.DRAFT)
  }

  @computed
  public get partyDraftContractsWithoutOffer(): IContract[] {
    return this.partyContractsWithNoOffer.filter(contract => contract.state === ContractState.DRAFT)
  }

  @computed
  public get partySignedContracts(): IContract[] {
    return this.partyContracts.filter(contract => contract.state === ContractState.SIGNED)
  }

  @computed
  private get party(): IParty {
    return this.rootStore.profileStore.party
  }

  @computed
  public get signedContractFiles(): ISignedContractFile[] {
    return this.files
  }

  public async loadSignedContractFiles(contractId: number) {
    // this.files = await this.contentService.getSignedContractFiles(contractId)
  }
}
