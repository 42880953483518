import React, {useRef, useCallback, useEffect} from 'react'
import Box from 'components/Box'
import Text from 'components/Text'
import Label from 'components/Label'
import useLocalization from 'hooks/useLocalization'
import {getChangeEventValue} from 'helpers/misc'
import Range from 'components/Range'
import {useAtom, useAtomValue} from 'jotai'
import ValueWithUnit from 'components/ValueWithUnit'
import {filterAtom} from 'atoms/certificateDesigner'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import {Tooltip} from '@mui/material'
import theme from 'theme'
import {min, debounce} from 'lodash-es'
import {LederhosenApiErrorType} from 'services/LederhosenApiErrorMapper'
import {monthAtom, yearAtom} from 'atoms/marketplace'
import {formatDecimal, formatNumber} from 'helpers/format'
import ApiError from 'exceptions/ApiError'

export const ALL_TECHNOLOGIES_SLUG = 'ALL'

export interface IFilter {
  dayOfMonth?: number
  quantity: number
}

interface IGeneralFilter {
  editable: boolean
  totalQuantity: number
  maxQuantity: number
  error: ApiError
  loading: boolean
}

export const EMPTY_WEEKDAY = 'None'

const GeneralFilter: React.FC<IGeneralFilter> = ({editable, totalQuantity, maxQuantity, error, loading}) => {
  const {translate} = useLocalization()
  const [filter, setFilter] = useAtom(filterAtom)
  const month = useAtomValue(monthAtom)
  const year = useAtomValue(yearAtom)
  const rangeRef = useRef<HTMLInputElement>(null)
  const [quantity, setQuantity] = React.useState<number>(filter.quantity)
  const hasConsumption = Boolean(totalQuantity && totalQuantity > 0)
  const maxAvailableQuantity = min([totalQuantity, maxQuantity])

  const handleQuantityChange = value => {
    const formattedValue = +getChangeEventValue(value)

    setQuantity(formattedValue)
    debounceFn(formattedValue)
  }

  const handleDebounceFn = quantity => {
    setFilter({...filter, quantity})
  }
  const debounceFn = useCallback(debounce(handleDebounceFn, 500), [])

  const getInfoMessages = () => {
    if (error?.type === LederhosenApiErrorType.NOT_ENOUGH_TOTAL_CERTIFICATES) {
      return (
        <>
          {translate(`No certificates for this month`)}
          <br />
          {translate(`Select another purchase interval`)}
        </>
      )
    }

    if (!loading) return translate('No consumption for this month')
  }

  useEffect(() => {
    setQuantity(filter.quantity)
  }, [loading])

  return (
    <>
      <Box pad={{bottom: 2}}>
        {hasConsumption ? (
          <Label text={translate('Estimated month volume')}>
            <ValueWithUnit value={formatDecimal(totalQuantity, 0)} unit={'CERTIFICATES'} />
          </Label>
        ) : (
          <Text color={theme.colors.warning} bold>
            {getInfoMessages()}
          </Text>
        )}
      </Box>
      <Label text={translate('Purchase interval')}>
        <Box direction="row" gap={1} pad={{bottom: 2}}>
          <ValueWithUnit value={month} />
          <ValueWithUnit value={year} />
        </Box>
      </Label>

      {hasConsumption && (
        <>
          <Label text={translate('Change volume')}>
            <Range
              disabled={!editable}
              ref={rangeRef}
              min={50}
              value={quantity}
              max={formatNumber(maxAvailableQuantity, 0)}
              step={1}
              onChange={quantity => handleQuantityChange(quantity)}
            />
          </Label>
          <Box justify="space-between" margin={{top: 1, bottom: 2}} align="end">
            <Text size="small" uppercase>
              {translate('Min')} <strong>{`${50} certificates`}</strong>
            </Text>
            <Box direction="row" align="end" gap={0.3}>
              {totalQuantity > maxQuantity && (
                <Tooltip
                  title={translate(
                    'The maximum volume you can purchase from this product without excess. Max volume might be limited due to shape of consumption/generation profile.',
                  )}
                >
                  <InfoOutlinedIcon fontSize="small" />
                </Tooltip>
              )}
              <Text size="small" uppercase>
                {translate('Max')} <strong>{`${maxAvailableQuantity.toFixed(0)} certificates`}</strong>
              </Text>
            </Box>
          </Box>
          <Box margin={{top: 1.5}}>
            <Label text={'Volume'}>
              <ValueWithUnit size="mlarge" value={formatDecimal(quantity, 0)} unit={'CERTIFICATES'} />
            </Label>
          </Box>
        </>
      )}
    </>
  )
}

export default GeneralFilter
