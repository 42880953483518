import React from 'react'
import styled from 'styled-components'
import Text from './Text'

const StyledLabel = styled.label<{left?: string; position?: string; top?: string}>`
  display: block;
  ${props =>
    props.position &&
    props.left &&
    props.top &&
    `
    position: absolute;
    left: ${props.left};
    top: ${props.top};
  `}
`

const LabelText = styled(Text).attrs({
  size: 'small',
  uppercase: true,
})`
  margin-bottom: 5px;
`

interface IProps extends React.PropsWithChildren {
  text: React.ReactNode
  position?: 'absolute'
  left?: string
  top?: string
}

const Label: React.FC<IProps> = ({text, position, left, top, children}) => (
  <StyledLabel left={left} top={top} position={position}>
    <LabelText>{text}</LabelText>
    {children}
  </StyledLabel>
)

export interface IWithLabelProps {
  label?: string
}

export const withLabel = <T extends Record<string, any>>(Component: any) => {
  // eslint-disable-next-line react/display-name
  return React.forwardRef(({label, ...props}: IWithLabelProps & T, ref: React.Ref<any>) => {
    return label ? (
      <Label text={label}>
        <Component {...(props as T)} ref={ref} />
      </Label>
    ) : (
      <Component {...(props as T)} ref={ref} />
    )
  }) as unknown as React.FC<IWithLabelProps & T>
}

export default Label
