import Box from 'components/Box'
import DropdownMenu, {DropdownMenuButton, DropdownMenuLink} from 'components/Dropdown'
import Table from 'components/Table'
import IManagedPartyMember from 'domain/IManagedPartyMember'
import {ManagedUserAction, ManagedUserStatus} from 'domain/IManagedUser'
import {PartyMemberRole} from 'domain/PartyMemberRole'
import {isPartyAdmin, stringifyMemberRole} from 'helpers/partyMember'
import useLocalization from 'hooks/useLocalization'
import useProfile from 'hooks/useProfile'
import React from 'react'

interface IProps extends React.PropsWithChildren {
  partyMembers: IManagedPartyMember[]
  urlPrefix: string
  loading?: boolean
  handleDisableUser: (asPartyId: number, managedPartyMember: IManagedPartyMember) => void
  handleResendInvite: (asPartyId: number, managedPartyMember: IManagedPartyMember) => void
  handleEnableUser: (asPartyId: number, managedPartyMember: IManagedPartyMember) => void
}

const getRole = (partyMember: IManagedPartyMember): PartyMemberRole => {
  const role = isPartyAdmin(partyMember) ? PartyMemberRole.PARTY_ADMIN : PartyMemberRole.PARTY_USER

  return role
}

const ManagePartyMembersTable: React.FC<IProps> = ({
  urlPrefix,
  partyMembers: users,
  loading,
  handleDisableUser,
  handleEnableUser,
  handleResendInvite,
}) => {
  const {translate} = useLocalization()
  const {user, party} = useProfile()

  const getAvailableActions = (
    partyMember: IManagedPartyMember,
  ): (typeof DropdownMenuButton | typeof DropdownMenuLink)[] => {
    let availableAction
    switch (partyMember.user.status) {
      case ManagedUserStatus.Active:
        availableAction = (
          <DropdownMenuButton height={2} onClick={() => handleDisableUser(party.id, partyMember)}>
            {ManagedUserAction.Suspend}
          </DropdownMenuButton>
        )
        break

      case ManagedUserStatus.Invited:
        availableAction = (
          <DropdownMenuButton height={2} onClick={() => handleResendInvite(party.id, partyMember)}>
            {ManagedUserAction.ResendInvite}
          </DropdownMenuButton>
        )
        break

      case ManagedUserStatus.Suspended:
        availableAction = (
          <DropdownMenuButton height={2} onClick={() => handleEnableUser(party.id, partyMember)}>
            {ManagedUserAction.Reactivate}
          </DropdownMenuButton>
        )
        break
    }

    const editAction = (
      <DropdownMenuLink to={`${urlPrefix}/company/members/edit/${partyMember.id}`}>
        {translate('Edit')}
      </DropdownMenuLink>
    )

    return [editAction, availableAction]
  }

  return (
    <Table<IManagedPartyMember>
      data={users}
      isLoading={loading}
      pad={{bottom: 8}}
      search={false}
      highlightOnHover
      linkTo={managedPartyMember => `${urlPrefix}/company/members/edit/${managedPartyMember.id}`}
      columns={[
        {title: translate('Email'), accessor: (partyMember: IManagedPartyMember) => partyMember.user.email},
        {
          title: translate('Name'),
          accessor: (managedPartyMember: IManagedPartyMember) => {
            return managedPartyMember.user.firstName + ' ' + managedPartyMember.user.lastName
          },
        },
        {
          title: translate('Role'),
          accessor: (managedPartyMember: IManagedPartyMember) =>
            stringifyMemberRole(getRole(managedPartyMember), translate),
        },
        {
          title: translate('Status'),
          accessor: (managedPartyMember: IManagedPartyMember) => managedPartyMember.user.status,
        },
        {
          title: translate('Actions'),
          disableLink: true,
          accessor: (managedPartyMember: any) => managedPartyMember,
          render: managedPartyMember => {
            return (
              <Box width={8} justify={'center'}>
                {managedPartyMember.user.email === user.email ? (
                  translate('(you)')
                ) : (
                  <DropdownMenu variant={'dottedIcon'} menuItems={getAvailableActions(managedPartyMember)} />
                )}
              </Box>
            )
          },
        },
      ].filter(Boolean)}
    />
  )
}

export default ManagePartyMembersTable
