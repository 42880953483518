import axios from 'axios'
import ApiError from 'exceptions/ApiError'
import IAuthenticationService from './IAuthenticationService'
import IHttpClient from './IHttpClient'
import env from '@beam-australia/react-env'

export interface IApiClientOptions {
  errorMappingFn?: (response: any) => ApiError
}

export default class ApiClient {
  public static create(
    authenticationService: IAuthenticationService,
    apiURL: string,
    options: IApiClientOptions = {},
  ): IHttpClient {
    const httpClient = axios.create({
      baseURL: apiURL,
      timeout: 10000,
    })
    const sessionId = crypto.randomUUID()

    httpClient.interceptors.request.use(async config => {
      try {
        let token = await authenticationService.getToken()
        const decodedToken = JSON.parse(atob(token.split('.')[1]))
        const expired = Date.now() >= decodedToken.exp * 1000

        if (expired) {
          token = await authenticationService.refreshToken()

          // TODO: remove this after testing
          try {
            await fetch(`${env('CORE_V2_URL')}/v2/api/prevented-expired-jwt-auth`)
          } catch (error) {
            console.info('caught expired token error')
          }
        }

        if (token !== null) {
          config.headers.authorization = `Bearer ${token}`
        }

        if (!config.headers.Accept) {
          config.headers.Accept = 'application/json'
        }

        config.headers['X-UI-TRACE-ID'] = sessionId

        return config
      } catch (error) {
        console.error('Failed to get token', error)
      }
    })

    httpClient.interceptors.response.use(undefined, error => {
      const {title, detail, message} = error?.response?.data || {}

      if (options?.errorMappingFn) {
        return Promise.reject(options.errorMappingFn(error?.response))
      }

      error.message = title || detail || message || error.message

      error.status = error?.response?.status
      error.errorType = error?.response?.data?.errorType

      return Promise.reject(error)
    })

    return httpClient
  }
}
