import React, {useEffect, useMemo} from 'react'
import useStore from 'hooks/useStore'
import {useRouteMatch} from 'react-router'
import InnerScroll from 'components/InnerScroll'
import useLocalization from 'hooks/useLocalization'
import ProductDetails from 'components/Platform/Buy/CertificateDesigner/ProductDetails'
import Box from 'components/Box'
import ActionBox from 'components/Platform/ActionBox'
import GeneralFilter from 'components/Platform/Buy/CertificateDesigner/GeneralFilter'
import ProposalDetailsChart from 'components/Platform/Buy/CertificateDesigner/ProposalDetailsChart'
import ProposalDetailsInfo from 'components/Platform/Buy/CertificateDesigner/ProposalDetailsInfo'
import ModalLink from 'components/Modal/ModalLink'
import {ModalType} from 'components/Modal/IModal'
import Button from 'components/Button'
import Text from 'components/Text'
import RequestModal from 'components/Platform/Buy/CertificateDesigner/RequestModal'
import {OfferState} from 'domain/IOffer'
import OfferProduct from 'components/Platform/Portfolio/Offer/OfferDesignerCertificate/OfferProduct'
import PreviewModal from 'components/Platform/Buy/CertificateDesigner/PreviewModal/PreviewModal'
import {useHistory} from 'react-router-dom'
import {ContractState} from 'domain/IContract'
import {Chip} from '@mui/material'
import ProductMap from 'components/Platform/Map/ProductMap'
import {useSetAtom, useAtom, useAtomValue} from 'jotai'
import {rootServiceAtom} from 'atoms/general'
import {
  filterAtom,
  offerAtom,
  offerIdAtom,
  productAtom,
  productIdAtom,
  proposalDetailsAtom,
  resetAtom,
} from 'atoms/certificateDesigner'
import {partyAtom} from 'atoms/party'
import theme from 'theme'
import {monthAtom, yearAtom} from 'atoms/marketplace'
import Heading from 'components/Heading'
import PageBase from 'components/Platform/Buy/CertificateDesigner/PageBase'
import {buyerProposalsAtom} from 'atoms/proposals'

const CertificateDesigner: React.FC = () => {
  const history = useHistory()
  const {alertStore} = useStore()
  const rootPath = '/consumer/buy/certificate-designer/offer'
  const queryParameters = new URLSearchParams(window.location.search)
  const yearParams = queryParameters.get('year')
  const monthParams = queryParameters.get('month')
  const {translate} = useLocalization()
  const {url} = useRouteMatch()
  const setProductId = useSetAtom(productIdAtom)
  const setOfferId = useSetAtom(offerIdAtom)
  const resetState = useSetAtom(resetAtom)
  const {contentService, v2ContentService} = useAtomValue(rootServiceAtom)
  const party = useAtomValue(partyAtom)
  const {data: product, loading: productLoading} = useAtomValue(productAtom)
  const [offerData, refetchOffer] = useAtom(offerAtom)
  const refetchBuyerProposals = useSetAtom(buyerProposalsAtom)
  const offer = offerData?.data
  const [month, setMonth] = useAtom(monthAtom)
  const [year, setYear] = useAtom(yearAtom)
  const {data: proposalDetails, error, loading} = useAtomValue(proposalDetailsAtom) || {}
  const [filter, setFilter] = useAtom(filterAtom)
  const offerMatch = useRouteMatch({path: `${url}/offer/:offerId`})
  const productMatch = useRouteMatch({path: `${url}/product/:productId`})
  const currentPartyCountry = party?.location?.addressCountry
  const restrictedToBuy = currentPartyCountry !== product?.site?.location?.addressCountry
  const offerId = offerMatch?.params['offerId']
  const productId = productMatch?.params['productId']
  const editable = !offerId || offer?.state === OfferState.CONFIG_IN_PROGRESS
  const totalMissing = (editable ? proposalDetails : offer)?.timeseries?.missing?.reduce(
    (acc, {value}) => acc + value,
    0,
  )
  const declined = offer?.state === OfferState.SELLER_DECLINED_CONFIG
  const draft = offer?.state === OfferState.CONFIG_IN_PROGRESS
  const signable = offer?.state === OfferState.CONTRACT_SENT && offer?.contract?.state === ContractState.OFFER
  const mapProduct = useMemo(() => [product].filter(Boolean), [product])
  const offerProposalDetails = {
    totalPrice: offer?.totalPrice,
    averagePrice: offer?.averagePrice,
    greenCoverage: offer?.greenCoverage,
    timeseries: offer?.timeseries,
    currency: offer?.currency,
  }

  const payloadProduct = editable ? product : offer?.product
  const filterPayload = {
    ...filter,
    month,
    year: +year,
    product: {productId: payloadProduct?.id, quantity: filter.quantity || payloadProduct?.quantity},
  }
  const isLoading = (productId && productLoading) || (offerId && offerData.loading)
  const hasConsumption = Boolean(totalMissing && totalMissing > 0)

  useEffect(() => {
    return () => resetState()
  }, [])

  useEffect(() => {
    if (yearParams) {
      setYear(+yearParams)
    }
    if (monthParams) {
      setMonth(monthParams)
    }
  }, [yearParams, monthParams])

  useEffect(() => {
    if (productId) {
      setProductId(productId)
    }
  }, [productId])

  useEffect(() => {
    if (offer) {
      setFilter({
        ...filter,
        quantity: offer?.product?.quantity,
      })
      setYear(offer.purchaseInterval.year)
      setMonth(offer.purchaseInterval.month)
      setProductId(offer.product.id)
    }
  }, [offer])

  useEffect(() => {
    if (offerId) {
      setOfferId(offerId)
    }
  }, [offerId])

  const handleRequest = async () => {
    try {
      if (offerId) {
        await contentService.sendCertificateReview(party.id, offerId)
      } else {
        const offer = await v2ContentService.saveCertificateOffer(party.id, filterPayload)
        await contentService.sendCertificateReview(party.id, offer.id)
        history.push(`${rootPath}/${offer.id}`)
      }
      refetchOffer()
      refetchBuyerProposals()

      alertStore.addSuccess(translate('Successfully sent request'))
    } catch (e) {
      alertStore.addError(translate('Failed to send request'), e?.correlationId, e?.message)
    }
  }

  const handleSave = async () => {
    try {
      const offer = await v2ContentService.saveCertificateOffer(party.id, filterPayload)

      if (offer) {
        alertStore.addSuccess(translate('Successfully saved %s', ''))
        history.push(`${rootPath}/${offer.id}`)
      }
    } catch (e) {
      alertStore.addError(translate('Failed to save %s', ''), e?.correlationId, e?.message)
    }
  }

  const handleEdit = async () => {
    try {
      const result = await contentService.editCertificateOffer(party.id, offerId, filterPayload)

      if (result) {
        alertStore.addSuccess(translate('Successfully edited %s', ''))
      }
    } catch (e) {
      alertStore.addError(translate('Failed to edit %s', ''), e?.correlationId, e?.message)
    }
  }

  if (isLoading) {
    return <PageBase loading={isLoading} />
  }

  if (offerData?.error) {
    return (
      <PageBase>
        <Box justify="center">
          <Heading margin={{top: 4}}>{translate('Failed to load offer')}</Heading>
        </Box>
      </PageBase>
    )
  }

  return (
    <PageBase
      description={
        declined ? (
          <Chip label={translate('Declined')} color="error" />
        ) : (
          translate('Review certificate mix, key terms and confirm the solution')
        )
      }
      descriptionAside={declined}
      corner={
        <Box gap={2}>
          {offerId && (
            <ModalLink modal={ModalType.CERTIFICATE_PREVIEW}>
              <Button variant="secondary" type="button">
                {translate('Preview')}
              </Button>
            </ModalLink>
          )}
          {editable && hasConsumption && !restrictedToBuy && (
            <Button onClick={offerId ? handleEdit : handleSave} variant="secondary">
              {translate('Save')}
            </Button>
          )}
          {(editable || draft) && hasConsumption && !restrictedToBuy && (
            <ModalLink modal={ModalType.CERTIFICATE_REQUEST}>
              <Button type="button">{translate('Request')}</Button>
            </ModalLink>
          )}
          {signable && (
            <ModalLink modal={ModalType.CERTIFICATE_PREVIEW}>
              <Button type="button">{translate('Sign')}</Button>
            </ModalLink>
          )}
        </Box>
      }
    >
      <RequestModal onSend={handleRequest} />
      {offer && <PreviewModal offer={offer} />}
      <InnerScroll noRightPad>
        <Box direction="column">
          <Box direction="row" gap={2}>
            <Box
              width="23%"
              border
              round={!editable}
              style={{borderRadius: editable ? '8px 8px 0 0' : null, borderBottom: editable ? 'none' : null}}
            >
              <ActionBox title={translate('General')} fullWidth noBorder>
                <GeneralFilter
                  key={`${offer?.name}/${product?.id}`}
                  editable={editable}
                  loading={loading}
                  totalQuantity={totalMissing}
                  maxQuantity={proposalDetails?.maxQuantity}
                  error={error}
                />
              </ActionBox>
            </Box>
            <Box
              width="77%"
              border
              round={!editable}
              style={{
                minHeight: 454,
                borderRadius: editable ? '8px 8px 0 0' : null,
                borderBottom: editable ? 'none' : null,
              }}
            >
              <ActionBox
                title={
                  <Box direction="row" justify="space-between" gap={2} $maxHeight="28px">
                    <Text size="mlarge" nowrap>
                      {translate('Offer details')}
                    </Text>
                    {offer?.state === OfferState.CONFIG_SENT_FOR_SELLER_REVIEW && !loading && (
                      <Text size="medium" color={theme.colors.info} bold>
                        {translate(
                          'Offer is currently in review by the seller. You will be notified once they approve or decline',
                        )}
                      </Text>
                    )}
                    {!hasConsumption && !error && !loading && (
                      <Text size="medium" color={theme.colors.warning} bold>
                        {translate('Upload your consumption')}
                      </Text>
                    )}
                    {hasConsumption && restrictedToBuy && !productLoading && (
                      <Text size="medium" color={theme.colors.warning} bold>
                        {translate(
                          "You can't buy from another country, contact Renewabl to move these certificates to your country",
                        )}
                      </Text>
                    )}
                  </Box>
                }
                fullWidth
                noMargin
                noBorder
              >
                <Box direction="column" gap={1.5}>
                  <ProposalDetailsInfo
                    draft={editable}
                    hasConsumption={hasConsumption}
                    proposalDetails={editable ? proposalDetails : offerProposalDetails}
                  />
                  <Box>
                    <ProposalDetailsChart
                      technology={product?.site.productionTechnology}
                      timeseries={proposalDetails?.timeseries}
                    />
                  </Box>
                </Box>
              </ActionBox>
            </Box>
          </Box>

          <Box width="100%" direction="row" style={{minHeight: editable ? 295 : null}} gap={2}>
            {editable && (
              <Box width="23%" title={translate('Add products')} border style={{borderRadius: '0px 0px 8px 8px'}}>
                <ProductMap products={mapProduct} />
              </Box>
            )}
            {editable && (
              <Box width="77%" border style={{borderRadius: '0 0 8px 8px'}}>
                <InnerScroll noRightPad>
                  <Box>
                    <ProductDetails product={product} quantity={filter.quantity} />
                  </Box>
                </InnerScroll>
              </Box>
            )}
            {!editable && (
              <Box width="100%" margin={{top: 2}}>
                <OfferProduct product={offer?.product} currency={offer?.currency} />
              </Box>
            )}
          </Box>
        </Box>
      </InnerScroll>
    </PageBase>
  )
}

export default CertificateDesigner
